/*
 * @Description: 用人单位底库
 * @Autor: CYF
 * @Date: 2022-03-28 09:26:28
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 13:53:50
 */
import ajax from '../ajax';

// 获取用人单位底库列表
export const getEmployerList = (data: {}) => ajax('/v3/admin/sys/company/getCompanyList', data, 'GET');

// 导出
export const exportList = (data: {}) => ajax('/v3/admin/sys/company/export', data, 'GET', { responseType: 'blob' });

// 获取用人单位信息
export const getEmployerInfo = (data: {}) => ajax('/v3/admin/sys/company/getCompany', data, 'GET');

// 修改用人单位信息
export const editEmployerInfo = (data: {}) => ajax('/v3/admin/sys/company/saveUser', data, 'POST');

// 获取反馈列表
export const getFeedbackList = (data: {}) => ajax('/v3/admin/sys/company/getFeedBackList', data, 'GET');
