/*
 * @Description: 关于我们
 * @Autor: CYF
 * @Date: 2022-03-25 09:19:47
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-31 09:55:06
 */
import ajax from '../ajax';

// 获取验证码
export const getAboutUs = () => ajax('/v2/www/api/about/getAbout', 'GET');
