/*
 * @Description: 重置密码弹框
 * @Autor: CYF
 * @Date: 2022-03-16 14:03:06
 * @LastEditors: CYF
 * @LastEditTime: 2023-10-08 14:16:10
 */

import { useEffect, useState } from 'react';
import CustomPopover from '@/components/CustomPopover';
import {
  Button, Form, Input, message,
} from 'antd';
import { useStores } from '@/store';
import rules from '@/configs/formRules';
import { rsaEncrypt } from '@/tools/tool';

import './index.less';

/**
 * @description: 重置密码弹框
 * @param {boolean} isModalVisible 是否显示弹框
 * @param {Function} setisModalVisible 设置是否显示弹框
 * @param {boolean} defaultIsChangePassword 默认是否显示修改密码
 * @param {Function} callback 修改密码成功后的回调函数(默认显示修改密码时传入)
 */
export default (({
  activeTab,
  isModalVisible,
  setisModalVisible,
  defaultIsChangePassword = false,
  callback,
}: {
  activeTab?: string,
  isModalVisible: boolean,
  setisModalVisible: Function,
  defaultIsChangePassword?: boolean,
  callback?: Function
}) => {
  const [form] = Form.useForm();
  const stores: any = useStores();
  const { loginStore } = stores;
  const {
    getEmailVcode,
    checkEmailVcode,
    getPublicKey,
    resetPasswordByEmail,
    resetPassword,
    resetAdminPassword,
  } = loginStore;

  // 是否显示密码弹框
  const [isChangePassword, setisChangePassword] = useState(defaultIsChangePassword);
  // 按钮是否可点击-邮箱
  const [isActive, setisActive] = useState(true);
  // 邮箱信息
  const [emailData, setemailData] = useState({
    email: '',
    code: '',
  });

  useEffect(() => {
    if (isModalVisible && defaultIsChangePassword) {
      setisChangePassword(true);
    } else {
      setisChangePassword(false);
    }
  }, [isModalVisible, defaultIsChangePassword]);

  /**
   * @description: 重置密码-邮箱-获取验证码事件
   */
  const onEmailVcode = () => {
    const emailVal = form.getFieldValue('email');
    if (!emailVal) {
      message.warning('请正确输入您的邮箱地址');
      return false;
    }
    setisActive(false);
    const data = {
      email: emailVal,
    };
    getEmailVcode(JSON.parse(JSON.stringify(data)), (res:any) => {
      if (res) {
        if (res?.result) {
          message.success('发送验证码成功,请至邮箱查收');
          setisActive(true);
        } else {
          message.error(res.message);
        }
      }
    });
  };

  /**
   * @description: 重置密码-邮箱-确定修改按钮点击事件
   */
  const onCheckEmail = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        const data = {
          email: fieldsValue.email,
          code: fieldsValue.vcode,
        };
        checkEmailVcode(JSON.parse(JSON.stringify(data)), (res: any) => {
          if (res?.result) {
            setemailData({ ...data });
            setisChangePassword(true);
          } else if (res?.result === false) {
            message.error('您的验证码有误，请确认后重新输入');
          } else {
            message.error(res?.message);
          }
        });
        return false;
      })
      .catch(() => { });
  };

  /**
   * @description: 重置密码-密码-请求
   * @param {any} fieldsValue
   * @param {any} key
   * @return {*}
   */
  const reqResetPassword = (fieldsValue: any, key: any) => {
    const data = {
      password: rsaEncrypt(fieldsValue.password, key),
    };
    if (defaultIsChangePassword && callback) {
      // 无需邮箱验证的重置密码
      if (activeTab === 'backstage') { // 后台用户需要用专门的接口
        resetAdminPassword({ ...data }, (res: any) => {
          if (res) {
            message.success('本账号登录密码重置成功');
            setisModalVisible(false);
            callback(res);
          }
          // if (res?.result) {
          //   message.success('本账号登录密码重置成功');
          //   setisModalVisible(false);
          //   callback(res);
          // } else if (res?.result === false) {
          //   message.error('重置密码失败，请稍后再试');
          // } else {
          //   message.error(res?.message);
          // }
        });
      } else {
        resetPassword({ ...data }, (res: any) => {
          if (res) {
            message.success('本账号登录密码重置成功');
            setisModalVisible(false);
            callback(res);
          }
          // if (res?.result) {
          //   message.success('本账号登录密码重置成功');
          //   setisModalVisible(false);
          //   callback(res);
          // } else if (res?.result === false) {
          //   message.error('重置密码失败，请稍后再试');
          // } else {
          //   message.error(res?.message);
          // }
        });
      }
    } else {
      // 需要邮箱验证的重置密码
      resetPasswordByEmail({ ...data, ...emailData }, (res: any) => {
        if (res) {
          message.success('本账号登录密码重置成功');
          setisModalVisible(false);
        }
        // if (res?.result) {
        //   message.success('本账号登录密码重置成功');
        //   setisModalVisible(false);
        // } else if (res?.result === false) {
        //   message.error('重置密码失败，请稍后再试');
        // } else {
        //   message.error(res?.message);
        // }
      });
    }
  };

  /**
   * @description: 重置密码-密码-确定修改按钮点击事件
   */
  const onResetPassword = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        if (fieldsValue.password !== fieldsValue.repeatPassword) {
          message.warning('确认密码与新密码不一致,请重新输入');
          return false;
        }
        getPublicKey().then((res: any) => {
          if (res) {
            if (res?.result) {
              reqResetPassword(fieldsValue, res.result);
              return false;
            }
            message.error(res.message);
          }
        });
      })
      .catch(() => { });
  };

  return (
    <CustomPopover
      className="reset-password-pop"
      isshow={isModalVisible}
      setisshow={setisModalVisible}
      customConfig={{
        footer: [
          <Button key="back" onClick={() => { setisModalVisible(false); }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={isChangePassword ? onResetPassword : onCheckEmail} disabled={!isChangePassword && !isActive}>
            确定修改
          </Button>,
        ],
        onOk: isChangePassword ? onResetPassword : onCheckEmail,
      }}
    >
      {isChangePassword ? (
        <Form
          form={form}
          className="reset-password-form"
          layout="vertical"
          name="checkEmail" // 表单名称，会作为表单字段 id 前缀使用
          scrollToFirstError
          preserve={false}
        >
          <Form.Item
            label="密码"
            name="password"
            required={false}
            rules={[...rules.password, { required: true, message: '请输入密码' }]}
          >
            <Input.Password placeholder="请输入密码（8位及以上包含大小写字母、数字）" />
          </Form.Item>
          <Form.Item
            label="确认密码"
            name="repeatPassword"
            required={false}
            rules={[{ required: true, message: '请再次输入密码' }]}
          >
            <Input.Password placeholder="请再次输入密码" />
          </Form.Item>
        </Form>
      ) : (
        <Form
          form={form}
          className="reset-password-form"
          layout="vertical"
          name="resetPassword" // 表单名称，会作为表单字段 id 前缀使用
          scrollToFirstError
          preserve={false}
        >
          <Form.Item
            label="邮箱"
            name="email"
            required={false}
            rules={rules.email}
          >
            <Input placeholder="请输入邮箱" />
          </Form.Item>
          <Form.Item
            label="验证码"
            name="vcode"
            required={false}
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <div className="email-vcode">
              <Input placeholder="请输入验证码" />
              <Button
                className="email-vcode-btn"
                onClick={onEmailVcode}
                disabled={!isActive}
              >
                发送验证码
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </CustomPopover>
  );
});
