import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Layout,
  Menu,
  Divider,
  Avatar,
  Badge,
  Button,
  Dropdown,
} from 'antd';
import {
  BellOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

import './index.less';
import { useStores } from '@/store';

import ServerFooter from '../components/serverFooter';
import ResetPassWord from '../components/resetPassWord';

/**
 * 前台页面-结构
 */
export default observer((props: any) => {
  const stores: any = useStores();
  const { loginStore, UserInfoStore, serverHomeStore } = stores;
  const { signOut } = loginStore;
  const { userRoleType, getUserInfoData, userInfo } = UserInfoStore;
  const { webTitle } = serverHomeStore;
  const { Header, Content } = Layout;
  const history: any = useHistory();
  const location: any = useLocation();

  const [hrefKey, setHrefKey] = useState('');
  const [headerClass, setHeaderClass] = useState('');
  const [showBack, setShowBack] = useState(false);
  // 是否显示修改密码弹框
  const [isModalVisible, setisModalVisible] = useState(false);

  /**
   * 定义存放对象
   */
  const configs = {
    backUrls: ['/login', '/register'],
  };

  /**
   * 工具函数存放对象
   */
  const tools = {
    /**
     * 获取当前路由所属模块
     */
    getHrefKey(path: string) {
      let returnKey = '/';
      if (/^\/home/.test(path)) {
        returnKey = '/home';
      }
      if (/^\/training/.test(path)) {
        returnKey = '/training';
      }
      if (/^\/news/.test(path)) {
        returnKey = '/news';
      }
      if (/^\/contact/.test(path)) {
        returnKey = '/contact';
      }
      if (/^\/user/.test(path)) {
        returnKey = '/userCenter';
      }

      return returnKey;
    },
  };

  /**
   * 下拉菜单
   */
  const menu = (
    <Menu>
      <Menu.Item
        key="editPassWord"
        onClick={() => {
          setisModalVisible(true);
        }}
      >
        修改密码
      </Menu.Item>
      <Menu.Item
        key="register"
        onClick={() => {
          signOut({}, () => { window.location.href = '/login'; });
        }}
      >
        退出系统
      </Menu.Item>
    </Menu>
  );

  /**
   * 操作处理函数存放对象
   */
  const handles = {
    /**
     * 菜单点击处理函数
     */
    goRoute(item: any) {
      let route = item.key;
      if (item.key === '/userCenter') {
        const type = localStorage.getItem('userType');
        if (type === '1') {
          route = '/user/myTrain';
        } else if (type === '2') {
          route = '/userUnit/unitUserTrain';
        } else {
          route = '/login';
        }
      }
      if (location.pathname === route) {
        return false;
      }
      history.push(route);
    },

    /**
     * 消息查看处理函数
     */
    goMessage() {
      let route = '';
      const type = localStorage.getItem('userType');
      if (type === '1') {
        route = '/user/messageNotice';
      } else if (type === '2') {
        route = '/userUnit/messageNotice';
      }
      history.push(route);
    },
  };

  // 路径变化监听-设置头部栏样式
  useEffect(() => {
    setHrefKey(tools.getHrefKey(location.pathname));
    window.scrollTo(0, 0);

    if (location.pathname === '/home') {
      setHeaderClass('ant-layout-header-home');
    } else if (location.pathname.includes('onlineWatch')) {
      setHeaderClass('ant-layout-header-onliewatch');
    } else {
      setHeaderClass('');
    }

    if (configs.backUrls.includes(location.pathname)) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }

    function handleScroll() {
      const sl = -Math.max(document.body.scrollLeft, document.documentElement.scrollLeft);
      const tl = -Math.max(document.body.scrollTop, document.documentElement.scrollTop);
      const el: any = document.querySelector('.ant-layout-header');
      el.style.left = `${sl}px`;

      if (location.pathname !== '/') {
        return false;
      }

      if (tl <= -500) {
        setHeaderClass('ant-layout-header-home-fixed');
      } else {
        setHeaderClass('ant-layout-header-home');
        el.style.left = '0px';
      }
    }

    window.addEventListener('scroll', handleScroll, false);
    return () => window.removeEventListener('scroll', handleScroll, false);
  }, [location.pathname]);

  // 路径变化监听-设置个人中心
  useEffect(() => {
    const type = localStorage.getItem('userType');
    if (location.pathname.includes('/user/')) {
      if (type !== '1') {
        history.push('/login');
      }
    }
    if (location.pathname.includes('/userUnit/')) {
      if (type !== '2') {
        history.push('/login');
      }
    }
  }, [location.pathname]);

  // 前台页面初始化
  useEffect(() => {
    if ([1, 2].includes(userRoleType)) { // 非后台用户获取用户信息
      getUserInfoData();
    }
  }, []);

  return (
    <div className="server-layout">
      <Layout className="server-layout-content" style={{ minHeight: '100vh' }}>
        <Header className={headerClass}>
          <div className="fliter-bg" />
          <div className="server-layout-header server-container">
            <div className="header-nav">
              <div className="logo">{webTitle}</div>
              <Menu
                mode="horizontal"
                theme="dark"
                onClick={handles.goRoute}
                selectable={false}
                selectedKeys={[hrefKey]}
              >
                <Menu.Item key="/home" title="首页">
                  首页
                </Menu.Item>
                <Menu.Item key="/training" title="在线培训">
                  在线培训
                </Menu.Item>
                <Menu.Item key="/news" title="资讯动态">
                  资讯动态
                </Menu.Item>
                {
                  [1, 2].includes(userRoleType)
                    ? (
                      <Menu.Item key="/userCenter" title="个人中心">
                        个人中心
                      </Menu.Item>
                    ) : <></>
                }
              </Menu>
            </div>
            {
              showBack
                ? (
                  <div className="header-back">
                    <Button type="text" className="primary-text-btn" onClick={() => history.push('/')}>返回首页</Button>
                  </div>
                )
                : (
                  <div className="header-user">
                    {
                      [1, 2].includes(userRoleType)
                        ? (
                          <div className="is-login">
                            <div className="item" onClick={handles.goMessage}>
                              <Badge count={userInfo?.unreadMsgNum} title={`有${userInfo?.unreadMsgNum}条提示信息`}>
                                <BellOutlined />
                              </Badge>
                            </div>
                            <Dropdown
                              overlay={menu}
                              overlayClassName="dropdown-box"
                            >
                              <div className="item"><Avatar src={userInfo.avatar || userInfo.headImg} icon={<UserOutlined />} size={32} /></div>
                            </Dropdown>
                          </div>
                        )
                        : (
                          <div className="not-login">
                            <div className="item" onClick={() => history.push('/register')}>注册</div>
                            <Divider type="vertical" />
                            <div className="item" onClick={() => history.push('/login')}>登录</div>
                          </div>
                        )
                    }
                  </div>
                )
            }
          </div>
        </Header>
        <Content style={{ marginTop: hrefKey === '/home' ? '0px' : '' }}>
          {props.children}
        </Content>
        <ServerFooter />
        <ResetPassWord isModalVisible={isModalVisible} setisModalVisible={setisModalVisible} />
      </Layout>
    </div>
  );
});
