import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getSysParam,
} from '@/api/server/public';
import { message } from 'antd';

/**
 * 前台页面通用状态
 */
class ServerHomeStore {
  // 网页点击数
  clickNum: number = 13242;

  // 系统标题
  webTitle: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取系统参数
   * @return {*}
   */
  @action getSysParam = async () => {
    const res: any = await getSysParam();
    runInAction(() => {
      if (res.state?.value === 0) {
        if (res.result && res.result.length) {
          res.result.forEach((item: { [key: string]: string }) => {
            if (item.code === 'web-title') {
              this.webTitle = item.value || '北京市放射卫生培训管理平台';
            }
          });
        }
      } else {
        message.error(res.message);
      }
    });
  }
}

export default new ServerHomeStore();
