import ajax from '@/api/ajax';
import type {
  Params, Result, TrainingListData, PersonalSignData, TrainingDetail, CourseGroupInfo,
} from './type.d';

/** 个人查看首页培训管理列表  */
const getPersonalTrainingList = (data: Params) => ajax('/v2/www/personal/train/getTrainingList', data, 'GET') as Promise<Result<TrainingListData>>;

/** 个人-培训管理列表  */
const getPersonalTrainingDetail = (data: {
  tid?: string
}) => ajax('/v2/www/personal/train/getTrainingBaseInfo', data, 'GET') as Promise<Result<TrainingDetail>>;

/** 个人-查看培训-课程组 信息 */
export const getPersonalTrainingCourseGroup = (data: {
  tid?: string
}) => ajax('/v2/www/personal/train/getTrainingCourseGroup', data, 'GET') as Promise<Result<CourseGroupInfo>>;

/** 个人-查看培训-课程组-课程 信息 */
export const getPersonalTrainingCourse = (data: {
  courseGroupId: string
  tid?: string
}) => ajax('/v2/www/personal/train/getTrainingCourse', data, 'GET') as Promise<Result<any>>;

// 个人报名提交
const postPersonalSign = (data: PersonalSignData) => ajax('/v2/www/personal/sign', data, 'POST') as Promise<Result<string>>;

export {
  getPersonalTrainingList,
  postPersonalSign,
  getPersonalTrainingDetail,
};
