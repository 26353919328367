import ajax from '@/api/ajax';
import {
  CommonParams, Result, CourseGroupDate, CourseGroup,
} from './type.d';

// 组-根据关键词搜索组
export const getGroupList = (data: CommonParams) => ajax('/v3/admin/course/group/search', data, 'GET') as Promise<Result<CourseGroupDate>>;

// 组-关键词模糊查询-获取组名和组id
export const getGroupByKeyword = (data: {
  keyword: string
}) => ajax('/v3/admin/course/group/getIdsAndNamesByKeyword', data, 'GET') as Promise<Result<any>>;

// 组-查看组详情
export const getGroupDetail = (data: {
  id: string
}) => ajax('/v3/admin/course/group/detail', data, 'GET') as Promise<Result<CourseGroup>>;

// 组-添加组
export const postAddGroup = (data: {
  name: string
}) => ajax('/v3/admin/course/group/add', data, 'POST') as Promise<Result<any>>;

// 组-预览某个组
export const getGroupPreview = (data: { id: number}) => ajax('/v3/admin/course/group/previewByGroupId', data, 'GET') as Promise<Result<CourseGroup>>;
// 组-预览某个课程的所有组

/**
 * 组-编辑组
 * @param {string} id 编号
 * @param {string} name 课程组名
 * @param {string} status 状态（0-关闭，1-启用）
 */
export const postGroupEdit = (data: {
  id: string
  name: string
  status: '0' | '1' | '2'
}) => ajax('/v3/admin/course/group/edit', data, 'POST') as Promise<Result<any>>;

// 组-启用、禁用
export const postGroupEditStatus = (data: {
  id: string,
  status: '1' | '2'
}) => ajax('/v3/admin/course/group/editStatus', data, 'POST') as Promise<Result<any>>;

// 删除组
export const deleteGroup = (data: {
  id: string
}) => ajax('/v3/admin/course/group/delete', data, 'POST') as Promise<Result<any>>;

// 组-批量操作
export const postGroupBatchEdit = (data: {
  courseGroupId: string
  type: '1' | '2'
}[]) => ajax('/v3/admin/course/group/edit/batchOpt', data, 'POST') as Promise<Result<any>>;

// 组-工作类别列表
export const getWorkTypeList = () => ajax('/v3/admin/course/group/getWorkTypeList', 'GET') as Promise<Result<any>>;

// 组-删除课程
export const delCourseList = (data: {
  groupId: string
  itemId: string
}[]) => ajax('/v3/admin/course/group/deleteItem/batch', data, 'POST') as Promise<Result<any>>;

export type {
  CommonParams,
  CourseGroup,
};
