import { message } from 'antd';
import {
  action,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  getMessageInfo,
  editMessageInfo,
} from '../../api/admin/messageManagement';
//  需求配置
class AdminMessageStore {
  // 数据的id
  @observable
  id: string = '';

 @observable messageInfo:any={}

 constructor() {
   makeAutoObservable(this);
 }

  @action getMessageInfo = async (data: any) => {
    const res: any = await getMessageInfo(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.messageInfo = res.result;
      }
    });
    return res;
  }

  @action editMessageInfo = async (data: any) => {
    const res: any = await editMessageInfo(data);
    runInAction(() => {
      if (res.state.value === 0) {
        message.success(res.message);
      } else {
        message.error(res.message);
      }
    });
    return res;
  }
}

export default new AdminMessageStore();
