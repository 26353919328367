/*
 * @Description: 系统日志
 * @Autor: CYF
 * @Date: 2022-03-29 15:06:31
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 16:07:37
 */
import ajax from '../ajax';

// 获取系统日志列表
export const getSystemLogList = (data: {}) => ajax('/v3/admin/sys/log/getLogListData', data, 'GET');

// 获取日志模块类型
export const getModuelTypeList = () => ajax('/v3/admin/sys/log/getLogModuleTypeList', 'GET');

// 获取日志操作类型
export const getOptionTypeList = () => ajax('/v3/admin/sys/log/getLogTypeList', 'GET');
