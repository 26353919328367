import ajax from '../ajax';

// 资讯动态-列表
export const getArticleList = (data: any) => ajax('/v2/www/api/article/getArticleList', data, 'GET');

// 资讯动态-置顶列表
export const getTopArticleList = () => ajax('/v2/www/api/article/getTopArticleList', {}, 'GET');

// 资讯动态-详情
export const getArticle = (data: any) => ajax('/v2/www/api/article/getArticle', data, 'GET');
