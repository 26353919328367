/*
 * @Description: 参数设置
 * @Autor: CYF
 * @Date: 2022-03-30 11:24:15
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-30 13:07:09
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getParameterList,
  editParameter,
  delParameter,
} from '@/api/admin/parameterManagement';
import { message } from 'antd';

/**
 * @description: 表示ParameterManagementStore状态管理类
 */
class ParameterManagementStore {
  // 参数列表
  parameterList: Array<any> = [];

  // 当前页
  activePagenum: number = 1;

  // 数据总数
  total: number = 0;

  // 表格加载
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取参数列表
   * @return {*}
   */
  @action getParameterList = async (data: {
    page: number;
    limit: number
  }) => {
    // 默认参数
    const defaultData = {
      page: 1,
      limit: 10,
    };
    this.loading = true;
    const res: any = await getParameterList({ ...defaultData, ...data });
    runInAction(() => {
      if (res.state?.value === 0) {
        const list = res.result?.data.map((item: any, index: number) => {
          const key = (index + 1).toString();
          return { ...{ key }, ...item };
        });
        this.parameterList = list;
        this.total = res.result?.total;
        this.activePagenum = res.result?.page;
        this.loading = false;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 新增/编辑参数
   * @param {*} data
   * @param {Function} callBack
   * @return {*}
   */
  @action editParameter = async (data: {}, callBack: Function) => {
    const res: any = await editParameter(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 删除参数-废弃
   * @param {*} data
   * @param {Function} callBack
   * @return {*}
   */
  @action delSuitWork = async (data: {}, callBack: Function) => {
    const res: any = await delParameter(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };
}

export default new ParameterManagementStore();
