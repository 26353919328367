import {
  ReactNode,
  Suspense,
  useEffect,
} from 'react';
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from 'react-router-dom';

import { useStores } from '@/store';

import Home from '@/layout/serverLayout';
import Admin from '@/layout/adminLayout';
import Login from '@/otherPages/login';
import Register from '@/otherPages/register';
import Loading from '@/otherPages/loading';

import adminRouteConfig from './adminRoutesConfig';
import serverRouteConfig from './serverRoutesConfig';
import otherRoutesConfig from './otherRoutesConfig';

/**
 * 路由生成
 * @param routesConfig 路由定义数据
 * @param routesArr 已生成的路由元素数组
 * @param path 路由路径起始url
 */
function RouteView() {
  const stores: any = useStores();
  const { serverHomeStore } = stores;
  const { getSysParam } = serverHomeStore;
  const renderRoutes = function (routesConfig: any[], routesArr: ReactNode[] = [], path: string = '') {
    routesConfig.forEach((item) => {
      if (item.redirect) {
        routesArr.push(
          <Redirect key={item.key} from={path + item.path} to={path + item.redirect} exact />,
        );
      } else {
        routesArr.push(
          <Route key={item.key} path={path + item.path} component={item.component} exact={!item.switch} />,
        );
      }
      if (item.children && Array.isArray(item.children)) {
        const newPath = `${path}${item.path}`;
        renderRoutes(item.children, routesArr, newPath);
      }
    });
    return routesArr;
  };

  useEffect(() => {
    // 获取系统参数
    getSysParam();
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin">
          <Admin>
            <Suspense fallback={<Loading />}>
              <Switch>
                {renderRoutes(adminRouteConfig)}
                <Route path="/admin/noPer">没有任何权限</Route>
                <Route>404未找到该页面</Route>
              </Switch>
            </Suspense>
          </Admin>
        </Route>
        <Route path="/other">
          <Suspense fallback={<Loading />}>
            <Switch>
              {renderRoutes(otherRoutesConfig)}
              <Route>404未找到该页面</Route>
            </Switch>
          </Suspense>
        </Route>
        <Route path="/">
          <Home>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path="/login"><Login /></Route>
                <Route path="/register"><Register /></Route>
                {renderRoutes(serverRouteConfig)}
                <Route>404未找到该页面</Route>
              </Switch>
            </Suspense>
          </Home>
        </Route>
        <Route>404未找到该页面</Route>
      </Switch>
    </BrowserRouter>
  );
}

export default RouteView;
