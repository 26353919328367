/*
 * @Description: 关于我们
 * @Autor: CYF
 * @Date: 2022-03-25 09:20:48
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-31 09:56:17
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getAboutUs,
} from '@/api/server/about';
import { message } from 'antd';

/**
 * @description: 表示AboutStore状态管理类
 */
class AboutStore {
  // 关于我们内容
  aboutContent: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 关于我们
   * @return {*}
   */
  @action getAbout = async () => {
    const res: any = await getAboutUs();
    runInAction(() => {
      if (res.state?.value === 0) {
        this.aboutContent = res.result?.content;
      } else {
        message.error(res.message);
      }
    });
  }
}

export default new AboutStore();
