/*
 * @Description: 系统日志
 * @Autor: CYF
 * @Date: 2022-03-29 15:06:02
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-06 18:28:17
 */
import {
  getSystemLogList,
  getOptionTypeList,
  getModuelTypeList,
} from '@/api/admin/systemLog';
import { message } from 'antd';
import { action, makeAutoObservable, runInAction } from 'mobx';

/**
 * @description: 表示SystemLogStore状态管理类
 */
class SystemLogStore {
  // 系统日志列表
  systemLogList: Array<{}> = [];

  // 当前页
  activePagenum: number = 1;

  // 数据总数
  total: number = 0;

  // 表格加载
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取系统日志列表
   */
  @action getSystemLogList = async (data: {
    page: number;
    limit: number
  }) => {
    // 默认参数
    const defaultData = {
      page: 1,
      limit: 10,
    };
    this.loading = true;
    const res: any = await getSystemLogList({ ...defaultData, ...data });
    runInAction(() => {
      if (res.state?.value === 0) {
        const list = res.result?.data.map((item: any, index: number) => {
          const key = (index + 1).toString();
          return { ...{ key }, ...item };
        });
        this.systemLogList = list;
        this.total = res.result?.total;
        this.activePagenum = res.result?.page;
        this.loading = false;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 获取日志操作类型
   */
  @action getOptionTypeList = async (data: {}, callBack: Function) => {
    const res: any = await getOptionTypeList();
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 获取日志模块类型
   */
  @action getModuelTypeList = async (data: {}, callBack: Function) => {
    const res: any = await getModuelTypeList();
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };
}

export default new SystemLogStore();
