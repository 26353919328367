/*
 * @Description: 信息更正反馈
 * @Autor: CYF
 * @Date: 2022-03-24 09:00:12
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-01 09:26:30
 */
import { useStores } from '@/store';
import { PlusSquareOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, message, Select,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { unitInfo } from '@/store/modal/registerStore';
import CustomPopover from '@/components/CustomPopover';
import ChooseUnit from '../ChooseUnit';
import './index.less';

export default observer((
  props: {
    isshow: boolean,
    setisshow: Function,
  },
) => {
  const [form] = Form.useForm();
  const { Option } = Select;

  const store: any = useStores();
  const { registerStore } = store;
  const { feedback } = registerStore;

  // 是否显示选择单位弹框
  const [isShowUnitPop, setisShowUnitPop] = useState(false);
  // 选中的单位id
  const [unitId, setunitId] = useState('');
  // 是否可提交
  const [isActive, setisActive] = useState(false);

  /**
   * @description: 获取选中的单位
   * @param {unitInfo} val
   * @return {*}
   */
  const handleChooseUnit = (val: unitInfo) => {
    form.setFieldsValue({ name: val.name });
    setunitId(val.id);
  };

  /**
   * @description: 提交反馈按钮点击事件
   * @param {*}
   * @return {*}
   */
  const onOk = () => {
    // 表单校验
    form
      .validateFields()
      .then((fieldsValue) => {
        // 请求参数
        const value = {
          // ...fieldsValue,
          cid: unitId,
          contactWay: fieldsValue.contactWay,
          content: fieldsValue.content,
          type: fieldsValue.type,
        };
        feedback(value, (res: any) => {
          if (res) {
            message.success('反馈成功');
            props.setisshow(false);
          }
        });
      })
      .catch(() => {});
  };

  return (
    <>
      <CustomPopover
        title="信息更正反馈"
        className="feedback-pop"
        isshow={props.isshow}
        setisshow={props.setisshow}
        customConfig={{
          getContainer: null,
          footer: [
            <Button key="back" onClick={() => { props.setisshow(false); }}>
              取消
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={onOk}
              className={isActive ? 'active' : ''}
              disabled={!isActive}
            >
              提交反馈
            </Button>,
          ],
          onCancel: () => { props.setisshow(false); },
          onOk,
        }}
      >
        <Form
          form={form}
          className="feedback-form"
          layout="vertical"
          name="feedback" // 表单名称，会作为表单字段 id 前缀使用
          initialValues={{}} // 默认值
          preserve={false} // 字段被删除时保留值
          scrollToFirstError // 提交失败自动滚动到第一个错误字段
          onValuesChange={(changedValues, allValues) => {
            let tmpisActive = true;
            Object.keys(allValues).forEach((key) => {
              if (!allValues[key]) {
                tmpisActive = false;
              }
            });
            setisActive(tmpisActive);
          }} // 字段值更新时触发回调事件
        >
          <Form.Item
            label="单位名称"
            name="name"
            hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
            required={false}
            rules={[{ required: true, message: '请选择用人单位' }]}
          >
            <Input
              placeholder="请选择用人单位"
              suffix={
                <PlusSquareOutlined style={{ fontSize: '24px', color: '#999', cursor: 'pointer' }} onClick={() => { setisShowUnitPop(true); }} />
              }
              readOnly
            />
          </Form.Item>
          <Form.Item
            label="反馈类型"
            name="type"
            hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
            required={false}
            rules={[{ required: true, message: '请选择反馈类型' }]}
          >
            <Select
              placeholder="请选择反馈类型"
              allowClear
            >
              <Option value="100001">单位名称有误</Option>
              <Option value="100002">统一社会信用代码有误</Option>
              <Option value="100003">注册地址有误</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="正确信息"
            name="content"
            hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
            required={false}
            rules={[{ required: true, message: '请输入正确信息...' }]}
          >
            <Input.TextArea placeholder="请输入正确信息..." />
          </Form.Item>
          <Form.Item
            label="联系方式"
            name="contactWay"
            hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
            required={false}
            rules={[{ required: true, message: '请输入您的电话或邮箱' }]}
          >
            <Input placeholder="请输入您的电话或邮箱" />
          </Form.Item>
        </Form>
      </CustomPopover>
      <ChooseUnit isshow={isShowUnitPop} setisshow={setisShowUnitPop} cb={handleChooseUnit} />
    </>
  );
});
