import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getDataList,
  deleteData,
  onShelfData,
} from '@/api/admin/trainingManagement';
import { message } from 'antd';

class AdminTrainingManagementStore {
  // 当前分页
  pageNum: number = 1;

  // 当前每页展示数
  pageSize: number = 10;

  // 当前数据总数
  total: number = 0;

  // 当前搜索关键字
  keyWord: string = '';

  // 当前搜索数据状态
  status: number|string|undefined = undefined;

  // 当前搜索工作类别
  type: number|string|undefined = undefined;

  // 当前表格loading
  loading: boolean = false;

  // 当前数据
  dataList: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取培训数据列表
   */
  @action getDataList = async (data: any) => {
    this.loading = true;
    const defaultData = {
      workType: this.type,
      keyword: this.keyWord,
      status: this.status,
    };
    const res: any = await getDataList({ ...defaultData, ...data });
    if (res.state.value === 0) {
      runInAction(() => {
        this.pageNum = res.result.page;
        this.dataList = res.result.data;
        this.total = res.result.total;
        this.loading = false;
        if (data.workType !== undefined) {
          this.type = data.workType !== '' ? data.workType : undefined;
        }
        if (data.status !== undefined) {
          this.status = data.status !== '' ? data.status : undefined;
        }
        if (data.keyword !== undefined) {
          this.keyWord = data.keyword !== '' ? data.keyword : undefined;
        }
      });
      return true;
    }
    return false;
  }

  /**
   * 删除培训数据
   */
  @action deleteData = async (data: any) => {
    const res: any = await deleteData(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }

  /**
   * 上下架培训数据
   */
  @action onShelfData = async (data: any) => {
    const res: any = await onShelfData(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error(res.message);
    return false;
  }
}

export default new AdminTrainingManagementStore();
