import { message } from 'antd';
import {
  action,
  makeAutoObservable,
  observable,
  runInAction,
} from 'mobx';
import {
  getInformationList,
  getInfoDetail,
  deleteDataInfo,
  editInfo,
  upOrDownInfo,
  isTopInfo,
} from '../../api/admin/information';

// 资讯动态列表全局配置
class AdminInformationStore {
  // tab表格展示的数据
  @observable
  list: any = [];

   // 数据的id
   @observable
   id: string = '';

  // 当前分页
  @observable
  page: number = 1;

  //  每页展示的数量
  @observable
  pages: number = 10;

  // 数据总数
  @observable
  total: number = 0;

   // 表格加载
   loading: boolean = false;

   // 查询关键字
   @observable
   keyword: string = '';

   // 查询数据状态
   @observable
   state: string|number = '';

    // 数据类别 类型：0101-通知公告；0102-信息资讯；0103-政策解读；0104-企业抗疫
    @observable
    type: string = '';

    // 是否置顶
    @observable
    isTop: boolean = false;

    constructor() {
      makeAutoObservable(this);
    }

    /**
   * 请求table列表数据方法
   * @function
   */
  @action getInformationList = async (data: any = {}) => {
    const res: any = await getInformationList({ ...data });
    this.loading = true;
    runInAction(() => {
      if (res.state.value === 0) {
        this.list = res.result.data;
        this.total = res.result.total;
        this.page = res.result.page;
        this.loading = false;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * 上下架培训数据
   */
     @action upOrDownInfo = async (data: any) => {
       const res: any = await upOrDownInfo(data);
       if (res.state.value === 0) {
         message.success(res.message);
         return true;
       } message.error(res.message);
       return false;
     }

     /**
   * 改变文章置顶状态
   * @function
   */
     @action isTopInfo = async (data: any) => {
       const res: any = await isTopInfo(data);
       console.log(res);
       if (res.state.value === 0) {
         message.success(res.message);
         return true;
       }
       message.error(res.message);
       return false;
     }

     /**
   * 删除table列表数据方法
   * @function
   */
    @action deleteDataInfo = async (data: { id: any }) => {
      const res: any = await deleteDataInfo(data);
      if (res.state.value === 0) {
        message.success(res.message);
        return true;
      }
      message.error(res.message);
      return false;
    }

    /**
   * 根据信息资讯id获取详细信息
   */
  @action getInfoDetail = async (data: { id: string }) => {
    const res: any = await getInfoDetail(data);
    if (res.state?.value === 0) {
      this.id = data.id;
      return res.result;
    }
    message.error(res.message);
    return false;
  }

// 编辑资讯动态
@action editInfo = async (data: any) => {
  const res: any = await editInfo(data);
  let result = false;
  runInAction(() => {
    if (res.state.value === 0) {
      this.id = res.data;
      result = true;
    } else {
      message.error(res.message);
    }
  });
  return result;
}
}

export default new AdminInformationStore();
