/*
 * @Date: 2022-03-09 15:31:24
 * @LastEditors: shencl
 * @LastEditTime: 2022-03-16 08:58:45
 * @Description: 功能描述
 * @FilePath: \training-platform\src\api\server\myExam.ts
 */
import ajax from '../ajax';
// 获取考试内容数据
export const getExamList = (data:any) => ajax('/v2/www/personal/myExam/getTrainingExamList', data, 'GET');
// 获取试题详情
export const getExamInfo = (data:any) => ajax('/v2/www/personal/myExam/getTuCourseExamPageList', data, 'GET');
// 获取培训基本信息
export const getTrainInfo = (data:any) => ajax('/v2/www/personal/myExam/getTrainingBaseInfo', data, 'GET');
// 培训课程考试记录
export const getExamHistory = (data:any) => ajax('/v2/www/personal/myExam/getCourseExamList', data, 'GET');
// 货期适用工作类型

export const getWorkType = () => ajax('/v2/www/api/train/getTrainingWorkType', 'GET');

// 获取证书

export const getCertificate = (data:any) => ajax('/v2/www/tuCertificate/getDetailById', data, 'GET');

// 获取考试类型
export const getExamTypeStatus = (data:any) => ajax('/v2/www/personal/myExam/getTrainingExamCount', data, 'GET');

// 获取考试详情类型
export const getExamInfoTypeStatus = (data:any) => ajax('/v2/www/personal/myExam/getTuCourseExamCount', data, 'GET');
