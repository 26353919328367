/*
 * @Date: 2022-03-28 09:38:38
 * @LastEditors: xgj
 * @LastEditTime: 2023-05-10 11:13:59
 * @Description: 功能描述
 * @FilePath: \training-platform\src\store\modal\structureManagementStore.ts
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getCertificateList,
  editCetStatus,
  updateCertificate,
  getCertificateInfo,
  removeCertificate,
  addCertificate,
  batchStatus,
  getTemplate,

} from '@/api/admin/certificateManagement';
import { reserveArrayBuffer } from 'mobx/dist/internal';

class CertificateManagementStore {
  // 当前分页
  pageNum: number = 1;

  // 分页容量
  pageSize: number = 5;

  // 当前数据总数
  total: number = 0;

  // 当前搜索关键字
  keyWord: string = '';

  // 试题类型
  questionType: string = '';

  // 当前搜索数据状态
  status: number | undefined = undefined;

  // 当前搜索工作类别
  type: number | undefined = undefined;

  // 当前数据
  certificateList: any[] = [];

  // 证书详情
  certificateInfo: any = {};

  // 模板数据

  templateList: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  // 获取证书列表
  @action getCertificateList = async (data: any) => {
    const res: any = await getCertificateList(data);
    if (res.state.value === 0) {
      runInAction(() => {
        this.pageNum = res.result.page;
        this.certificateList = res.result.data;
        this.total = res.result.total;
      });
      return true;
    }
    return false;
  };

  // 更改证书启用状态
  @action editCetStatus = async (data: any) => {
    const res: any = await editCetStatus(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  };

  // 批量修改状态
  @action batchStatus = async (data: any) => {
    const res: any = await batchStatus(data);
    if (res.state.value === 0) {
      runInAction(() => {});
    }
    return res;
  };

  // 更新证书
  @action updateCertificate = async (data: any) => {
    const res: any = await updateCertificate(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  };

  // 删除证书
  @action removeCertificate = async (data: any) => {
    const res: any = await removeCertificate(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  };

  // 查看证书详情

  @action getCertificateInfo = async (data: any) => {
    const res: any = await getCertificateInfo(data);
    if (res.state.value === 0) {
      runInAction(() => {
        this.certificateInfo = res.result;
      });
      return res.result;
    }
    return false;
  };

  // 新增证书

  @action addCertificate = async (data: any) => {
    const res: any = await addCertificate(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  };

  // 获取证书模板
  @action getTemplateList = async (data: any) => {
    const res: any = await getTemplate(data);
    if (res.state.value === 0) {
      const list = res?.result.map((item:any) => ({
        label: item.name,
        value: item.path,
      }));
      this.templateList = list;
      return res.result;
    }
    return false;
  };
}

export default new CertificateManagementStore();
