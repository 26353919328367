import ajax from '../ajax';

// 查询下级行政区划-返回树
export const getAreaTreeList = (data: any) => ajax('/v3/admin/api/area/getAreaTreeList', data, 'GET');
// 获取系统的所有工作类别
export const getAllWorkType = (data: any) => ajax('/v3/admin/sys/param/getParamList', { pcode: 100, isForbidden: 0, ...data }, 'GET');
// 获取课程组添加弹框数据
export const getCourseGroupList = (data: any) => ajax('/v3/admin/course/group/search', data, 'GET');
// 获取课程添加弹框数据
export const getCourseList = (data: any) => ajax('/v3/admin/course/item/search', data, 'GET');
// 查询课程详情
export const getCourseDetail = (data: any) => ajax('/v3/admin/course/item/detail', data, 'GET');
// 课程组-查看课程组详情
export const getCourseGroupDetail = (data: any) => ajax('/v3/admin/course/group/detail', data, 'GET');
// 手动更新培训状态
export const updateTrain = (data: any = {}) => ajax('/v3/admin/trainingManage/train/upateAllEnrollAStatus', data, 'POST');
// 清除缓存
export const clearStore = (data: any = {}) => ajax('/v3/admin/cache/clean', data, 'GET');
// 后台生成证书
export const generateCertificate = (data: any) => ajax('/v3/admin/tuCertificate/generateCertificate', data, 'GET');
// 后台查看证书
export const getCertificateDetail = (data: any) => ajax('/v3/admin/tuCertificate/getDetailById', data, 'GET');
