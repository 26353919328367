/*
 * @Description: 输入下拉选择
 * @Autor: CYF
 * @Date: 2022-03-17 14:37:47
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-17 14:41:14
 */
import { useStores } from '@/store';
import { observer } from 'mobx-react-lite';
import DebounceSelect, { UnitMsg } from './DebounceSelect';

export default observer((
  props: {
    tipStr: String;
    getSelectResult: Function;
    defaultData: UnitMsg;
    ifIncludeSelected?: number
  },
) => {
  const store: any = useStores();
  const { registerStore } = store;
  const { dataList, getUnitList } = registerStore;

  return (
    <DebounceSelect
      tipStr={props.tipStr}
      getSelectResult={props.getSelectResult}
      defaultData={props.defaultData}
      getDataList={getUnitList}
      dataList={dataList}
      debounceTimeout={800}
      ifIncludeSelected={props.ifIncludeSelected}
    />
  );
});
