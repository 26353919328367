/*
 * @Description: 注册
 * @Autor: CYF
 * @Date: 2022-03-17 09:55:17
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-31 10:49:54
 */
import { personUser, unitUser } from '@/store/modal/registerStore';
import ajax from '../ajax';

// 获取验证码
export const getVerificationCode = (data: { email: string }) => ajax('/v2/www/register/code', data, 'GET');

// 个人用户注册
export const registerPerson = (data: personUser) => ajax('/v2/www/register/user', data, 'POST');

// 单位用户注册
export const registerUnit = (data: unitUser) => ajax('/v2/www/register/companyUser', data, 'POST');

// 获取用人单位列表
export const getUnitList = (data: { page: number }) => ajax('/v2/www/api/company/serachPage', data, 'GET');

// 反馈
export const feedback = (data: {}) => ajax('/v2/www/api/company/feedback', data, 'POST');
