/*
 * @Description: 用人单位选择
 * @Autor: CYF
 * @Date: 2022-03-24 10:42:35
 * @LastEditors: CYF
 * @LastEditTime: 2022-06-27 14:45:29
 */
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  Button, Empty, Radio, Space,
} from 'antd';
import { useStores } from '@/store';
import { unitInfo } from '@/store/modal/registerStore';

import CustomPopover from '@/components/CustomPopover';
import AreaCascader from '@/components/areaCascader';
import SearchInp from '@/components/searchInp';
import Pagination from '@/components/Pagination';
import iconChecked from '@/assets/images/register/icon-checked.png';
import iconUnCheck from '@/assets/images/register/icon-uncheck.png';
import './index.less';

export default observer((
  props: {
    isshow: boolean,
    setisshow: Function,
    cb: Function,
    defaultVal?: unitInfo,
  },
) => {
  const store: any = useStores();
  const { registerStore } = store;
  const { getUnitList, dataList, total } = registerStore;

  // 单选框选中的值
  const [checkVal, setcheckVal] = useState('');
  const [value, setValue] = useState<unitInfo>();
  // 搜索的值
  const [searchAreaId, setsearchAreaId] = useState('');
  const [searchKeyword, setsearchKeyword] = useState('');
  // 分页
  const [pageNumber, setpageNumber] = useState(1);
  // 分页器配置
  const PaginationConfig: {} = {
    current: pageNumber,
    pageSize: 10,
    total: Number(total),
    onChange: (pagenum: number, pagesize: number) => {
      setpageNumber(pagenum);
      getUnitList({
        page: pagenum,
        limit: pagesize,
        areaId: searchAreaId,
        keyword: searchKeyword,
      });
    },
  };
  // 默认值
  const DefaultValue = {
    id: '',
    name: '',
    creditCode: '',
    area: '',
  };

  useEffect(() => {
    if (props.isshow) {
      getUnitList({
        page: 1, limit: 10, areaId: searchAreaId, keyword: searchKeyword,
      });
      if (props.defaultVal?.id) {
        setcheckVal(props.defaultVal?.id);
        setValue({ ...props.defaultVal });
      }
    }
  }, [props.isshow, searchAreaId, searchKeyword]);

  /**
   * @description: 设置默认值
   * @param {*}
   * @return {*}
   */
  const initVal = () => {
    setcheckVal('');
    setValue(DefaultValue);
    setsearchAreaId('');
    setsearchKeyword('');
  };

  /**
   * @description: 地区选择改变事件
   * @param {Array} val 选中区域的id列表
   * @param {Array} selectedOptions 选中区域的详情列表
   * @return {*}
   */
  const onAreaChange = (val: Array<string>, selectedOptions: Array<any>) => {
    setsearchAreaId(val.slice(-1)[0]);
  };

  /**
   * @description: 搜索框改变事件
   * @param {string} val
   * @return {*}
   */
  const onKeywordChange = (val: string) => {
    setsearchKeyword(val);
  };

  /**
     * @description: 单选框改变事件
     * @param {any} e 事件对象
     */
  const onChange = (e: any) => {
    const tmpData = {
      id: e.target.value,
      name: e.target['data-name'],
      creditCode: e.target['data-unitcode'],
      area: e.target['data-address'],
    };
    setcheckVal(e.target.value);
    setValue({ ...tmpData });
  };

  /**
   * @description: 取消按钮点击事件
   * @return {*}
   */
  const onCancelChecked = () => {
    setcheckVal('');
    setValue(DefaultValue);
  };

  /**
   * @description: 确定按钮点击事件
   * @param {*}
   * @return {*}
   */
  const onOk = () => {
    props.cb(value);
    initVal();
    props.setisshow(false);
  };

  return (
    <CustomPopover
      title="用人单位单选"
      className="unitchoose-pop"
      isshow={props.isshow}
      setisshow={props.setisshow}
      customConfig={{
        width: 664,
        getContainer: null,
        footer: [
          <Button key="back" onClick={() => { initVal(); props.setisshow(false); }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={onOk} disabled={!value?.id}>
            确定
          </Button>,
        ],
        onCancel: () => { props.setisshow(false); },
        onOk,
      }}
    >
      <div className="search-bar">
        <AreaCascader onChange={onAreaChange} />
        <SearchInp
          placeholder="请输入搜索关键词"
          style={{ flex: 1 }}
          onChange={onKeywordChange}
        />
      </div>
      <div className="content-box">
        <div className="content-box-list">
          {dataList && dataList.length ? (
            <Radio.Group onChange={onChange} value={checkVal}>
              <Space direction="vertical" size={12}>
                {dataList.map((item: unitInfo) => (
                  <Radio
                    key={item.id}
                    value={item.id}
                    data-name={item.name}
                    data-unitcode={item.creditCode}
                    data-address={item.address}
                    className="list-item"
                  >
                    <div className="flex-box list-item-top">
                      <div className="list-item-top-left">
                        <div className="title">{item.name}</div>
                        <div className="title-sub">{item.creditCode}</div>
                      </div>
                      <img src={checkVal === item.id ? iconChecked : iconUnCheck} alt="选择" />
                    </div>
                    <div className="list-item-bottom">{item.fullAddress}</div>
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          ) : (<Empty />)}
        </div>
        <div className="pagination-box">
          <Pagination className="pop-pagination" config={{ ...PaginationConfig }} />
        </div>
      </div>
      <div className="result-box flex-box">
        <div>
          <span>已选择用人单位：</span>
          <span className="blue">{value?.name}</span>
        </div>
        {value ? (<Button className="red" onClick={onCancelChecked}>取消选择</Button>) : null}
      </div>
    </CustomPopover>
  );
});
