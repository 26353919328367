import ajax from '../ajax';
// 资讯请求
//  获取资讯列表
// export const getInformation = (data: any) => ajax('/mock/admin/information', data, 'GET');
export const getInformationList = (data: any) => ajax('/v3/admin/web/article/getArticleList', data, 'GET');

// 获取资讯详情
export const getInfoDetail = (data: any) => ajax('/v3/admin/web/article/getArticle', data, 'GET');

// 资讯动态删除
export const deleteDataInfo = (data: any) => ajax('/v3/admin/web/article/del', data, 'POST');

// 资讯动态编辑
export const editInfo = (data: any) => ajax('/v3/admin/web/article/saveArticle', data, 'POST');

// 资讯动态上下架
export const upOrDownInfo = (data: any) => ajax('/v3/admin/web/article/saveUpOrDown', data, 'POST');

// 资讯动态置顶
export const isTopInfo = (data: any) => ajax('/v3/admin/web/article/saveIsTop', data, 'POST');
