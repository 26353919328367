/* eslint-disable no-return-assign */
/* eslint-disable lines-between-class-members */
import {
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getCourseSearch, getCouseDetail, getStructureList, getWorkType, postAddCourse, postBatchOpt, postDeleteCourse, postEditFullInfo,
} from '@/api/admin/courseManagement/course';
import { AddCourseParam, CourseItemVoList, Structure } from '@/api/admin/courseManagement/type';
import { message } from 'antd';
import { getWorkTypeList } from '@/api/admin/courseManagement/courseGroup';

class AdminCourseListStore {
  courseItemId?: string; // 当前弹窗所对的课程ID
  currentColData: CourseItemVoList | undefined; // 当前选择行数据
  isBatch: boolean = false; // 是否批量操作
  setIsBatch = () => this.isBatch = !this.isBatch;

  isCheckDrawerVisible: boolean = false; // 是否显示查看课程
  setCheckDrawer = (type: boolean, id?: string, list?: CourseItemVoList) => {
    this.isCheckDrawerVisible = type;
    this.courseItemId = id;
    this.currentColData = list;
  };
  isDrawerVisible: boolean = false; // 是否显示新增或编辑抽屉
  setDrawer = (type: boolean, id?: string, list?: CourseItemVoList) => {
    this.isDrawerVisible = type;
    this.courseItemId = id;
    this.currentColData = list;
  };

  isDelModalVisible: boolean = false; // 删除课程组弹窗状态
  setDelModal = (type: boolean, id?: string) => {
    this.isDelModalVisible = type;
    this.courseItemId = id;
  };

  areaId?: number; // 行政区划id
  jobCategory?: number; // 当前工作类别
  setJobCategory = (jobCategory: number) => this.jobCategory = jobCategory;
  keyword: string = ''; // 当前关键词
  setKeyword = (keyword: string) => this.keyword = keyword;
  current: number = 1; // 当前分页
  setCurrent = (current: number) => this.current = current
  pageSize: number = 10; // 当前每页展示数
  setPageSize = (current: number) => this.pageSize = current
  total: number = 0; // 当前数据总数

  list: CourseItemVoList[] = []; // 当前数据

  isLoading: boolean = false; // 表格loading

  constructor() {
    makeAutoObservable(this);
  }
  // 请求数据
  getCourseList = (status?: number, limit = this.pageSize) => {
    this.isLoading = true;
    getCourseSearch({
      areaId: this.areaId,
      jobCategory: this.jobCategory,
      keyword: this.keyword,
      page: this.current,
      limit,
      status,
    }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.isLoading = false;
          this.list = res.result.data;
          this.total = res.result.total;
        });
      }
    });
  }

  // 课程查看详情
  getCourseDetail = () => {
    this.isLoading = true;
    getCouseDetail({ courseItemId: this.courseItemId }).then((res) => {
      if (res.state.value === 0) {
        this.currentColData = res.result;
        message.success('获取课程详情成功');
        runInAction(() => {
          this.isLoading = false;
        });
      }
    });
  }

  // 删除课程组 @params id
  deleteCourse = () => {
    postDeleteCourse({ id: this.courseItemId as string }).then((res) => {
      if (res.state.value === 0) {
        message.success('课程删除成功');
      }
      this.getCourseList();
    });
    this.isDelModalVisible = false;
  }

  /** 添加课程组 */
  addCourseGroup = (data: AddCourseParam) => {
    this.isLoading = true;
    postAddCourse(data).then((res) => {
      if (res.state.value === 0) {
        message.success('课程添加成功');
      } else {
        message.error(res.message);
      }
      runInAction(() => {
        this.isLoading = false;
        this.getCourseList();
      });
    });
  }

  jobCategoryList: {
    label: string,
    value: string
  }[] = []
  // 获取工作类别列表
  getWorkTypeList = () => {
    getWorkTypeList().then((res) => {
      if (res.state.value === 0) {
        const temp = res.result.map((item: {
          code: string,
          name: string
        }) => ({
          label: item.name,
          value: item.code,
        }));
        runInAction(() => {
          this.jobCategoryList = temp ?? [];
        });
      }
    });
  }

  workTypeList?: {
    code: string,
    desc: string
    id: string
    isForbidden: 0 | 1 // 是否停用（1-禁用；0-启用）
    name: string
    pcode: string
    pic: string
  }
  // 获取工作类别
  getWorkType = (keyword: string) => {
    this.isLoading = true;
    getWorkType({
      pcode: '100',
      keyword,
      isForbidden: 1,
    }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.isLoading = false;
          this.workTypeList = res.result;
        });
      }
    });
  }

  // // 编辑课程组状态修改
  // editCourseGroupStatus = (id: number, name: string, status: any) => {
  //   // 请求数据，并重新请求表格数据
  // };

  // 试卷结构列表
  structureList: Structure[] = [];
  // 总数
  strTotal: number = 0;
  // 获取试卷结构列表
  getStructureList = (keyword: string, limit: number, page: number) => {
    this.isLoading = true;
    getStructureList({
      keyword, limit, page, status: 1,
    }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.isLoading = false;
          this.strTotal = res.result.total;
          this.structureList = res.result.data;
        });
      }
    });
  }

  // 批量操作课程
  editBatchCourse = (list: string[], type: '1' | '2' | '3', close: () => void) => {
    if (list.length === 0) {
      message.info('请选择课程后在进行操作');
    } else {
      this.isLoading = true;
      const temp = list.map((item) => ({
        courseItemId: item,
        type,
      }));
      postBatchOpt(temp).then((res) => {
        if (res.state.value === 0) {
          if (res.result.length === 0) {
            if (list.length === 1) {
              message.success('切换状态成功');
            } else {
              message.success('批量切换状态成功');
            }
          } else {
            let temp2: string = '';
            const temp3 = res.result.map((item: any) => item.courseGroupId);
            // eslint-disable-next-line array-callback-return
            this.list?.map((item: any) => {
              if (temp3.includes(item.id)) {
                temp2 += item.name;
              }
            });
            message.error(`${temp2}课程已被培训选中，无法修改`);
          }
        } else {
          message.error(res.message);
        }
        close();
        runInAction(() => {
          this.isLoading = false;
          this.getCourseList();
        });
      });
    }
  }

  // 编辑切换课程状态
  editCourse = (id: string, data: any) => {
    this.isLoading = true;
    postEditFullInfo({ ...data, id }).then((res) => {
      if (res.state.value === 0) {
        message.success('切换状态成功');
      } else {
        message.info(res.message);
      }
      runInAction(() => {
        this.isLoading = false;
        this.getCourseList();
      });
    });
  }
}

export default new AdminCourseListStore();
