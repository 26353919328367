/* eslint-disable no-return-assign */
/* eslint-disable lines-between-class-members */
import {
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  deleteGroup,
  getGroupByKeyword, getGroupDetail, getGroupList, getWorkTypeList, postAddGroup, postGroupBatchEdit, postGroupEdit, postGroupEditStatus,
} from '@/api/admin/courseManagement/courseGroup';
import type { CourseGroup } from '@/api/admin/courseManagement/courseGroup';
import { message } from 'antd';

class AdminCourseManagementStore {
  constructor() {
    makeAutoObservable(this);
  }
  // 当前用户全选
  isEditer: boolean = [...JSON.parse(localStorage.getItem('permission') || '[]')].includes('training_course:edit')
  /** 当前弹窗所对的课程组ID */
  currentModalId: string = '';
  /** 当前弹窗所对的课程组状态,为编辑课程组添加 */
  status: '0' | '1' | '2' = '0';
  /** 是否批量操作 */
  isBatch: boolean = false;
  setIsBatch = () => this.isBatch = !this.isBatch;
  /** 编辑课程组弹窗状态 */
  isEditModalVisible: boolean = false;
  setEditModal= (type: boolean, id: string = '', status: '0' | '1' | '2' = '0') => {
    this.isEditModalVisible = type;
    this.currentModalId = id;
    this.status = status;
  };
  /** 删除课程组弹窗状态 */
  isDelModalVisible: boolean = false;
  setDelModal = (type: boolean, id: string = '') => {
    this.isDelModalVisible = type;
    this.currentModalId = id;
  };
  /** 所选课程组名称 */
  name: string = '';
  setName = (name: string) => this.name = name;
  /** 所选课程组包含课程列表 */
  courseItemVoList?: any[] = [];
  setCourseItemVoList = (list: any[]) => this.courseItemVoList = list;
  /** 课程组详情抽屉状态 */
  isDetailDrawerVisible: boolean = false;
  setDetailDrawer= (type: boolean, id: string = '') => {
    this.isDetailDrawerVisible = type;
    this.currentModalId = id;
  };

  isAddCourseModalVisible: boolean = false;
  setAddCourseModal= (type: boolean) => {
    this.isAddCourseModalVisible = type;
  };
  /** 行政区划id */
  areaId?: number;
  /** 当前工作类别 */
  jobCategory?: number;
  setJobCategory = (jobCategory: number) => this.jobCategory = jobCategory;
  /** 当前关键词 */
  keyword: string = '';
  setKeyword = (keyword: string) => this.keyword = keyword;
  /** 当前分页 */
  current: number = 1;
  setCurrent = (current: number) => this.current = current
  /** 当前每页展示数 */
  pageSize: number = 10;
  setPageSize = (current: number) => this.pageSize = current
  /** 当前数据总数 */
  total?: number = 0;

  list?: CourseGroup[] = []; // 当前数据

  isLoading: boolean = false; // 表格loading

  // 请求数据
  getCourseList = (limit = this.pageSize) => {
    this.isLoading = true;
    getGroupList({
      areaId: this.areaId,
      jobCategory: this.jobCategory,
      keyword: this.keyword,
      limit,
      page: this.current,
    }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.isLoading = false;
          this.list = res?.result?.data;
          this.total = res?.result?.total;
        });
      } else {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    });
  }
  // 查看组详情
  getGroupDetail = () => {
    getGroupDetail({ id: this.currentModalId }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.name = res.result.name;
          this.courseItemVoList = res.result.courseItemVoList;
        });
      }
    });
  }

  // 删除课程组 @params id
  deleteCourseGroup = () => {
    // 请求数据，并重新请求表格数据
    this.isLoading = true;
    deleteGroup({ id: this.currentModalId }).then((res) => {
      if (res.state.value === 0) {
        message.success('删除成功');
      } else {
        message.error(res.message);
      }
      runInAction(() => {
        this.isLoading = false;
        this.getCourseList();
      });
    });
    this.isDelModalVisible = false;
  }

  // 添加课程组 @params name
  addCourseGroup = (name: string) => {
    this.isLoading = true;
    postAddGroup({ name }).then((res) => {
      if (res.state.value === 0) {
        message.success('编辑成功');
        runInAction(() => {
          this.isLoading = false;
          this.getCourseList();
        });
      } else {
        message.error(res.message);
        runInAction(() => {
          this.isLoading = false;
        });
      }
    });
  }

  // 编辑课程组
  editCourseGroup = (name: string) => {
    this.isLoading = true;
    postGroupEdit({
      id: this.currentModalId, name, status: this.status,
    }).then((res) => {
      if (res.state.value === 0) {
        message.success('编辑成功');
      } else {
        message.error(res.message);
      }
      runInAction(() => {
        this.isLoading = false;
        this.getCourseList();
      });
    });
  };

  // 编辑课程组状态
  editCourseGroupStatus = (id: string, status: '1' | '2') => {
    this.isLoading = true;
    postGroupEditStatus({ id, status }).then((res) => {
      if (res.state.value === 0) {
        message.success('切换状态成功');
      } else {
        message.error(res.message);
      }
      runInAction(() => {
        this.isLoading = false;
        this.getCourseList();
      });
    });
  }

  // 根据关键词搜索组名和组id
  // searchByKeyword = () => {
  //   getGroupByKeyword({
  //     keyword: this.keyword,
  //   }).then((res: any) => {
  //     console.log(res);
  //   });
  // }

  // 批量修改课程组
  editBatchGroup = (list: string[], type: '1' | '2', close: () => void) => {
    if (list.length === 0) {
      message.info('请选择课程组后在进行操作!');
    } else {
      this.isLoading = true;
      const temp = list.map((item) => ({
        courseGroupId: item,
        type,
      }));
      postGroupBatchEdit(temp).then((res) => {
        close();
        if (res.state.value === 0) {
          if (res.result.length === 0) {
            if (list.length === 1) {
              message.success('切换状态成功');
            } else {
              message.success('批量切换状态成功');
            }
          } else {
            let temp2: string = '';
            const temp3 = res.result.map((item: any) => item.courseItemId);
            // eslint-disable-next-line array-callback-return
            this.list?.map((item: any) => {
              if (temp3.includes(item.id)) {
                temp2 += item.name;
              }
            });
            message.error(`${temp2}课程组已被培训选中，无法修改`);
          }
        } else {
          message.error(res.message);
        }
        runInAction(() => {
          this.isLoading = false;
          this.getCourseList();
        });
      });
    }
  }

  workTypeList: {
    label: string,
    value: string
  }[] = []
  // 获取工作类别列表
  getWorkTypeList = () => {
    getWorkTypeList().then((res) => {
      if (res.state.value === 0) {
        const temp = res.result.map((item: {
          code: string,
          name: string
        }) => ({
          label: item.name,
          value: item.code,
        }));
        runInAction(() => {
          this.workTypeList = temp ?? [];
        });
      }
    });
  }
}

export default new AdminCourseManagementStore();
