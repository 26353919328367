/*
 * @Descripttion:
 * @version:
 * @Author: Fanzy
 * @Date: 2022-03-14 10:15:50
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-07 10:21:23
 */
import ajax from '../ajax';

// 获取用户信息(个人)
export const getUserInfo = (data: any) => ajax('/v2/www/personal/uc/getPersonalInfo', data, 'POST_FORM');

// 获取用户信息(单位)
export const getUnitUserInfo = (data: any) => ajax('/v2/www/corp/uc/getCorpInfo', data, 'GET');
