/*
 * @Description: 用人单位底库
 * @Autor: CYF
 * @Date: 2022-03-28 09:29:34
 * @LastEditors: CYF
 * @LastEditTime: 2022-05-27 20:50:13
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getEmployerList,
  exportList,
  getEmployerInfo,
  editEmployerInfo,
  getFeedbackList,
} from '@/api/admin/employerManagement';
import { message } from 'antd';

export interface employerFeedbackItem {
  contactWay?: '', // 联系方式
  content?: '', // 反馈内容
  createDate?: '', // 反馈时间
  id: '', // 反馈id
  status?: '', // 状态
  type?: '', // 反馈类型
  userId?: '', // 反馈人id
}

/**
 * @description: 表示EmployerManagementStore状态管理类
 */
class EmployerManagementStore {
  // 用人单位列表
  employerList: Array<{}> = [];

  // 当前页
  activePagenum: number = 1;

  // 数据总数
  total: number = 0;

  // 表格加载
  loading: boolean = false;

  // 文件上传地址
  fileUploadUrl: string = '/v3/admin/sys/company/import';

  // 用人单位反馈列表
  feedbackList: Array<{}> = [];

  // 用人单位反馈总数
  feedbackTotal: number = 0;

  // 用人单位反馈当前页
  feedbackActivePagenum: number = 1;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取用人单位列表
   */
  @action getEmployerList = async (data: {
    page: number;
    limit: number
  }) => {
    // 默认参数
    const defaultData = {
      page: 1,
      limit: 10,
    };
    this.loading = true;
    const res: any = await getEmployerList({ ...defaultData, ...data });
    runInAction(() => {
      if (res.state?.value === 0) {
        const list = res.result?.data.map((item: any, index: number) => {
          const key = (index + 1).toString();
          return { ...{ key }, ...item };
        });
        this.employerList = list;
        this.total = res.result?.total;
        this.activePagenum = res.result?.page;
        this.loading = false;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 导出
   * @param {object} data 请求参数
   */
  @action exportList = async (data: {}) => {
    const res: any = await exportList({ ...data });
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
    const a = document.createElement('a');
    const blobHref = window.URL.createObjectURL(blob);
    a.href = blobHref;
    a.download = '用人单位底库';
    a.click();
    window.URL.revokeObjectURL(blobHref);
  };

  /**
   * @description: 获取用人单位详情
   */
  @action getEmployerInfo = async (data: {
     id: string
   }, callBack: Function) => {
    const res: any = await getEmployerInfo(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 编辑用人单位详情
   */
  @action editEmployerInfo = async (data: {}, callBack: Function) => {
    const res: any = await editEmployerInfo(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 获取用人单位反馈列表
   */
  @action getFeedbackList = async (data: {
    page: number;
    limit: number
  }) => {
    // 默认参数
    const defaultData = {
      page: 1,
      limit: 10,
    };
    const res: any = await getFeedbackList({ ...defaultData, ...data });
    runInAction(() => {
      if (res?.state?.value === 0) {
        this.feedbackList = res.result?.data;
        this.feedbackTotal = res.result?.total;
        this.feedbackActivePagenum = res.result?.page;
      } else {
        message.error(res.message);
      }
    });
  };
}

export default new EmployerManagementStore();
