/*
 * @Description: 区域四级下拉
 * @Autor: CYF
 * @Date: 2022-03-17 17:41:57
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-06 14:58:32
 */
import {
  useEffect, useState, useImperativeHandle,
} from 'react';
import {
  Row, Col,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useStores } from '@/store';
import SearchItem from './SelectItem';

/**
 * @description: 区域四级下拉
 * @param {string} defaultValueData 默认区域id
 * @param {Boolean} disabled 是否可选中（整体）
 * @param {Function} cb 回调函数 param1：{ id：string, name: string } 选中项； param2: string[] 选中的四级id
 * @param {any} ref
 * @return {*}
 */
const MultiSelect = ({
  cb,
  defaultValueData = '',
  disabled = false,
}: {
  cb: Function,
  defaultValueData: string,
  disabled?: boolean,
  }, ref: any) => {
  // 省列表
  const [province, setprovince] = useState<{ id: string, name: string }[]>([]);
  // 市列表
  const [city, setcity] = useState<Array<{ id: string, name: string }>>([]);
  // 区列表
  const [country, setcountry] = useState<Array<{ id: string, name: string }>>([]);
  // 街道列表
  const [street, setstreet] = useState<Array<{ id: string, name: string }>>([]);
  // 四级选中项
  const [selectArr, setselectArr] = useState<string[]>([]);

  const store: any = useStores();
  const { MultiSelectStore } = store;
  const { getAreaList, getAreaInfo } = MultiSelectStore;

  /**
   * @description: 传入默认值时获取当前级的默认值
   * @param {number} level
   * @return {*}
   */
  function getData(level: number) {
    switch (level) {
      case 0:
        return selectArr[0] || '';
      case 1:
        return selectArr[1] || '';
      case 2:
        return selectArr[2] || '';
      case 3:
        return selectArr[3] || '';
      default:
        return '';
    }
  }

  /**
   * @description: 传入默认值时保存当前级的默认值
   * @param {object} list
   * @param {number} level
   * @return {*}
   */
  function setData(list: { id: string, name: string }[], level: number) {
    switch (level) {
      case 0:
        setprovince(list);
        break;
      case 1:
        setcity(list);
        break;
      case 2:
        setcountry(list);
        break;
      case 3:
        setstreet(list);
        break;
      default:
        break;
    }
  }

  /**
   * @description: 传入默认值时获取默认值的详细信息
   * @param {string} value
   * @return {*}
   */
  function getInfo(value: string) {
    getAreaInfo({ id: value }, (res: any) => {
      if (res && res.result) {
        const tmpAreaList = ['', '', '', ''];
        if (res.result.provinceId) {
          setprovince([{ id: res.result.provinceId, name: res.result.provinceName || '' }]);
          tmpAreaList[0] = res.result.provinceId;
        } else if (res.result.provinceId === null) {
          setprovince([]);
        }
        if (res.result.cityId) {
          setcity([{ id: res.result.cityId, name: res.result.cityName || '' }]);
          tmpAreaList[1] = res.result.cityId;
        } else if (res.result.cityId === null) {
          setcity([]);
        }
        if (res.result.areaId) {
          setcountry([{ id: res.result.areaId, name: res.result.areaName || '' }]);
          tmpAreaList[2] = res.result.areaId;
        } else if (res.result.areaId === null) {
          setcountry([]);
        }
        if (res.result.streetId) {
          setstreet([{ id: res.result.streetId, name: res.result.streetName || '' }]);
          tmpAreaList[3] = res.result.streetId;
        } else if (res.result.streetId === null) {
          setstreet([]);
        }
        setselectArr(tmpAreaList);
      }
    });
  }

  /**
   * @description: 获取当前级的下拉列表
   * @param {string} value 父级id
   * @param {number} level
   * @return {*}
   */
  function getList(value: string, level: number = 0) {
    if (value) {
      getAreaList({ pid: value }, (res: any) => {
        if (res && res.result) {
          setData(res.result, level);
        }
      });
    } else {
      setData([], level);
    }
  }

  // 暴露给父组件的hook
  useImperativeHandle(ref, () => ({
    reset: () => {
      setstreet([]);
      setcountry([]);
      setcity([]);
      setprovince([]);
      setselectArr([]);
    },
  }));

  useEffect(() => {
    if (defaultValueData) {
      getInfo(defaultValueData);
    }
  }, [defaultValueData]);

  /**
   * @description: 下拉框选中事件
   * @param {string} selected
   * @param {string} name
   * @param {number} level
   * @return {*}
   */
  function selectedFn(selected: string, name: string, level: number) {
    let tmpArr = [...selectArr];
    switch (level) {
      case 0:
        tmpArr = [selected, '', '', ''];
        setcity([]);
        setcountry([]);
        setstreet([]);
        break;
      case 1:
        tmpArr = [tmpArr[0], selected, '', ''];
        setcountry([]);
        setstreet([]);
        break;
      case 2:
        tmpArr = [tmpArr[0], tmpArr[1], selected, ''];
        setstreet([]);
        break;
      case 3:
        tmpArr[3] = selected;
        break;
      default: break;
    }
    setselectArr(tmpArr);
    cb({ id: selected, name }, tmpArr);
  }

  /**
   * @description: 下拉菜单展开事件回调
   * @param {number} level
   * @return {*}
   */
  function clickedFn(level: number) {
    switch (level) {
      case 0:
        getList('0');
        break;
      case 1:
        getList(selectArr[0], 1);
        break;
      case 2:
        getList(selectArr[1], 2);
        break;
      case 3:
        getList(selectArr[2], 3);
        break;
      default:
        break;
    }
  }

  /**
   * @description: 渲染四级选择框
   * @param {*}
   * @return {*}
   */
  function rednerArr() {
    const arr = [
      { list: province, placeholder: '请选择省' },
      { list: city, placeholder: '请选择市' },
      { list: country, placeholder: '请选择区' },
      { list: street, placeholder: '请选择街道' },
    ];
    return arr;
  }

  /**
   * @description: 渲染下拉列表
   * @param {*}
   * @return {*}
   */
  function renderItem(item: {
    list: { id: string, name: string }[],
    placeholder: string
  }, index: number) {
    return (
      <SearchItem
        key={index}
        list={item.list}
        placeholder={item.placeholder}
        selectedFn={selectedFn}
        level={index}
        disabled={disabled}
        defaultValue={getData(index)}
        clickedFn={() => clickedFn(index)}
      />
    );
  }
  return (
    <Row>
      <Col span={24}>
        {
          rednerArr().map((item, index) => renderItem(item, index))
        }
      </Col>
    </Row>
  );
};

export default observer(MultiSelect, { forwardRef: true });
