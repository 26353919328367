/*
 * @Author: SCL
 * @Date: 2021-06-08 17:36:50
 * @LastEditTime: 2022-04-06 14:59:46
 * @LastEditors: CYF
 * @Description: 下拉选框
 */
import { useState, useEffect } from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function SelectItem(
  {
    list,
    placeholder,
    selectedFn,
    level,
    defaultValue,
    disabled,
    clickedFn,
  }: {
    list: { id: string, name: string }[], // 下拉列表
    placeholder?: string, // 提示
    selectedFn?: Function, // 选中回调
    level: number,
    defaultValue?: string, // 默认值
    disabled: boolean // 是否禁用
    clickedFn: Function // 展开下拉菜单的回调
  },
) {
  // 选中的值
  const [selectValue, setselectValue] = useState(defaultValue);
  /**
   * @description: 下拉框选中事件
   * @param {string} value 下拉框选中值（id）
   * @param {any} e 事件对象
   * @return {*}
   */
  function selected(value: string, e: any) {
    setselectValue(value);
    if (selectedFn) {
      selectedFn(value, e.children, level);
    }
  }
  useEffect(() => {
    if (list?.length === 0) {
      setselectValue(undefined);
    }
  }, [JSON.stringify(list)]);
  useEffect(() => {
    if (defaultValue) {
      setselectValue(defaultValue);
    }
  }, [defaultValue]);
  return (
    <Select
      placeholder={placeholder}
      onChange={selected}
      value={selectValue}
      style={{ minWidth: '100px' }}
      disabled={disabled}
      onDropdownVisibleChange={() => { clickedFn(); }}
    >
      {
        list?.map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)
      }
    </Select>
  );
}

SelectItem.defaultProps = {
  placeholder: '请选择',
  selectedFn: () => { },
  defaultValue: '',
};
