import {
  Breadcrumb,
} from 'antd';
import './index.less';
import { useLocation, Link } from 'react-router-dom';
import routeConfigs from '@/router/adminRoutesConfig';

/**
 * 获取当前路由数组能组成的路径数组
 * @param pathArr 路由数组
 * @returns
 */
function getPathUrlArr(pathname: string) {
  const pathArr: string[] = pathname.split('/').slice(1);
  let path = '';
  const resArr: string[] = [];
  pathArr.forEach((item: string) => {
    path = `${path}/${item}`;
    resArr.push(path);
  });
  return resArr;
}

/**
 * 展开定义路由
 * @param routesConfig 定义的路由
 * @param routesArr 处理后返回的路由
 * @param path 起始路径
 * @returns
 */
function flatRoutes(routesConfig: any[], routesArr: {path: string, key: string, redirect?: string}[] = [], path: string = '') {
  routesConfig.forEach((item) => {
    routesArr.push(
      {
        path: (path + item.path).replaceAll(/^:\d{n}/ig, '222'),
        key: item.key,
        redirect: item.redirect,
      },
    );
    if (item.children && Array.isArray(item.children)) {
      const newPath = `${path}${item.path}`;
      flatRoutes(item.children, routesArr, newPath);
    }
  });
  return routesArr;
}

/**
 * 获取面包屑数组
 * @param pathArr
 * @param configArr
 */
function renderBread(pathArr: string[], configArr: any[]) {
  const resArr = []; // 处理后返回的数组
  const flatArr = flatRoutes(configArr); // 展开后的定义数组
  const RegArr = flatArr.map((item) => { // 定义数组的正则匹配
    const regTest = item.path.replaceAll(/:[a-zA-Z]+/ig, '[^/]+');
    return new RegExp(`^${regTest}$`, 'g');
  });
  for (let i = 0; i < pathArr.length; i += 1) {
    for (let j = 0; j < flatArr.length; j += 1) {
      if (RegArr[j].test(pathArr[i])) {
        resArr.push({
          path: pathArr[i],
          key: flatArr[j].key,
          redirect: flatArr[j].redirect,
        });
        break;
      }
    }
  }
  return resArr;
}

/**
 * 后台-面包屑导航
 */
const GenerateBread = () => {
  const location: any = useLocation();
  return (
    <Breadcrumb className="admin-breadcrumb" separator="-">
      {
        renderBread(getPathUrlArr(location.pathname), routeConfigs).map((item) => {
          const { key } = item;
          if (item.key === '后台') {
            return '';
          }
          if (item.redirect) {
            return (
              <Breadcrumb.Item key={key}>
                {item.key}
              </Breadcrumb.Item>
            );
          }
          return (
            <Breadcrumb.Item key={key}>
              <Link to={{ pathname: item.path }}>{item.key}</Link>
            </Breadcrumb.Item>
          );
        })
      }
    </Breadcrumb>
  );
};

export default GenerateBread;
