import ajax from '@/api/ajax';
import type {
  Draft, Params, Result, Staff, StaffParam, TrainingListData, TrainingDetail, CourseGroupInfo,
} from './type.d';

// 单位报名-查看登陆后-首页培训管理列表
export const getCropTrainingList = (data: Params) => ajax('/v2/www/crop/train/getTrainingList', data, 'GET') as Promise<Result<TrainingListData>>;

/** 单位报名-查看培训基本信息  */
export const getCropTrainingDetail = (data: {
  trainingId?: string
}) => ajax('/v2/www/crop/train/getTrainingBaseInfo', data, 'GET') as Promise<Result<TrainingDetail>>;

/** 单位报名-查看培训-课程组 信息  */
export const getCropTrainingCourseGroup = (data: {
  tid?: string
}) => ajax('/v2/www/crop/train/getTrainingCourseGroup', data, 'GET') as Promise<Result<CourseGroupInfo>>;

/** 单位报名-查看培训-课程组-课程 信息  */
export const getCropTrainingCourse = (data: {
  trainCourseGroupId: string
  tid?: string
}) => ajax('/v2/www/crop/train/getTrainingCourse', data, 'GET') as Promise<Result<any>>;

// 单位报名-存草稿
// export const postSaveDraftInfo = (data: Draft) => ajax('/v2/www/corp/uc/saveDraftInfo', data, 'POST') as Promise<Result<any>>;

// 单位报名-批量存草稿
export const postSaveDraftInfoList = (data: Draft[]) => ajax('/v2/www/corp/uc/saveDraftInfoList', data, 'POST') as Promise<Result<any>>;

// 单位报名-单个员工报名
// export const postSignUp = (data: {
//   enrollDetailId?: string
// }) => ajax('/v2/www/corp/uc/signUp', data, 'POST') as Promise<Result<any>>;

// 单位报名-多个员工报名
export const postCollSignUp = (data: {
  enrollDetailId?: string
  tid?:string
}[]) => ajax('/v2/www/corp/uc/collSignUp', data, 'POST') as Promise<Result<any>>;

// 单位报名-编辑员工草稿信息
export const postEditStaffInfo = (data: Staff) => ajax('/v2/www/corp/uc/editStaffInfo', data, 'POST') as Promise<Result<any>>;

// 单位报名-删除一个员工草稿信息
export const postDeleteOne = (data: {
  enrollDetailId: string
}) => ajax('/v2/www/corp/uc/deleteOne', data, 'POST') as Promise<Result<any>>;

// 单位报名-批量删除员工信息
export const postDeleteBatch = (data: {
  enrollDetailIds: string[]
}) => ajax('/v2/www/corp/uc/deleteBatch', data, 'POST') as Promise<Result<any>>;

// 单位员工信息草稿导入
export const postImport = (data: FormData) => ajax('/v2/www/corp/uc/import', data, 'POST') as Promise<Result<any>>;

// 单位报名-搜索员工信息草稿
export const postSearch = (data: StaffParam) => ajax('/v2/www/corp/uc/search', data, 'POST') as Promise<Result<any>>;

// 单位报名-单位退回转草稿
export const postChangeReturned = (data: {
  enrollId: string
}) => ajax('/v2/www/corp/uc/saveReturnedToDraft', data, 'POST') as Promise<Result<any>>;
