/*
 * @Description: 单位用户
 * @Autor: CYF
 * @Date: 2022-03-16 18:31:50
 * @LastEditors: CYF
 * @LastEditTime: 2022-06-01 08:22:54
 */
import {
  Button, Form, Input, Tooltip,
} from 'antd';
import { useRef, useState } from 'react';
import rules from '@/configs/formRules';
import iconTip from '@/assets/images/register/icon-tip.png';
import MultiSelect from '@/components/MultiSelect';
import UnitSelect from './customSelect';
import { UnitMsg } from './DebounceSelect';
import Feedback from './Feedback';

export default (({
  form,
  onEmailVcode,
  setisActive,
  setcid,
}:{
  form: any,
  onEmailVcode: Function,
  setisActive: Function,
  setcid: Function,
}) => {
  const child: any = useRef();

  // 是否选择单位
  const [isSelectUnit, setisSelectUnit] = useState(false);
  // 是否显示反馈弹框
  const [isShowPop, setisShowPop] = useState(false);
  // 区域id
  const [areaId, setareaId] = useState('');

  /**
   * @description: 校验表单是否填写完成（form.setFieldsValue不会触发form的onValuesChange事件需要手动校验）
   * @param {*}
   * @return {*}
   */
  const triggerCheck = () => {
    let tmpIsActive = true;
    Object.values(form.getFieldsValue()).forEach((val) => {
      if (val) {
        tmpIsActive = false;
      }
    });
    setisActive(tmpIsActive);
  };

  /**
   * @description: 获取选择结果
   * @param {UnitMsg} data
   * @return {*}
   */
  const getSelectResult = (data: UnitMsg) => {
    setisSelectUnit(!!data?.unitId);
    if (data?.unitId) {
      form.setFieldsValue({
        companyName: data.unitName,
        creditCode: data.unitCode,
        areaId: data.address,
      });
      setcid(data.unitId);
      setareaId(data.address || '');
      if (!data.address) {
        child.current.reset();
      }
    } else {
      form.setFieldsValue({
        companyName: data?.unitName,
        creditCode: '',
        areaId: '',
      });
      // 手动触发表单校验
      form.validateFields(['companyName', 'creditCode']);
      setcid('');
      setareaId('');
      child.current.reset();
    }
    triggerCheck();
  };

  /**
   * @description: 获取区域四级下拉选择结果
   * @param {object} data 选中项
   * @param {string} arrData 选中四级id列表
   * @return {*}
   */
  const getAreaResult = (data: { id: string, name: string }, arrData: string[]) => {
    setareaId(data?.id);
    if (!data?.id) {
      child.current.reset();
    }
    form.setFieldsValue({
      areaId: data?.id || '',
    });
    triggerCheck();
  };

  return (
    <>
      <Form.Item
        label={(
          <div className="flex-box">
            <span>用人单位名称</span>
            <Tooltip
              placement="bottom"
              title="填写单位名称时，若单位已在系统中记录，系统会自动搜索，您只要选择即可，选择后信息不可修改，若有错误请点击底部“我要反馈“按钮输入正确信息和联系方式进行反馈；单位信息在系统中无记录的，则需要填写正确的单位信息。"
              color="#fff"
              overlayClassName="custom-tooltip-box"
            >
              <img src={iconTip} alt="提示" />
            </Tooltip>
          </div>
        )}
        name="companyName"
        hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
        required={false}
        rules={[{ required: true, message: '请输入或选择您的单位全称' }]}
      >
        <UnitSelect
          defaultData={{
            unitId: '',
            unitName: '',
            unitCode: '',
            address: '',
          }}
          tipStr="请输入或选择您的单位全称"
          getSelectResult={getSelectResult}
        />
      </Form.Item>
      <Form.Item
        label="统一社会信用代码"
        name="creditCode"
        hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
        required={false}
        rules={[...rules.creditCode, { required: true, message: '请输入统一社会信用代码' }]}
      >
        <Input autoComplete="off" placeholder="请输入统一社会信用代码" disabled={isSelectUnit} />
      </Form.Item>
      <Form.Item
        className="address-box"
        label="注册地址"
        name="areaId"
        required={false}
        rules={[{ required: true, message: '请选择注册地址' }]}
      >
        <MultiSelect
          cb={getAreaResult}
          defaultValueData={areaId}
          disabled={isSelectUnit}
          ref={child}
        />
      </Form.Item>
      <Form.Item
        label="联系电话"
        name="contactNumber"
        hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
        required={false}
        rules={[{ required: true, pattern: new RegExp(/^[0-9]+(-?[0-9]+)?$/, 'g'), message: '请输入您的联系电话' }]}
      >
        <Input autoComplete="off" placeholder="请输入您的联系电话" />
      </Form.Item>
      <Form.Item
        label="单位邮箱"
        name="email"
        hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
        required={false}
        rules={rules.email}
      >
        <Input autoComplete="off" placeholder="请输入您的单位邮箱" />
      </Form.Item>
      <Form.Item
        label="设置密码"
        name="password"
        required={false}
        rules={[...rules.password, { required: true, message: '请输入您的密码' }]}
      >
        <Input.Password autoComplete="new-password" placeholder="请输入密码（8位及以上包含大小写字母、数字）" />
      </Form.Item>
      <Form.Item
        label="确认密码"
        name="repeatPassword"
        required={false}
        rules={[{ required: true, message: '请再次输入您的密码' }]}
      >
        <Input.Password autoComplete="new-password" placeholder="请再次输入您的密码" />
      </Form.Item>
      <Form.Item
        label="验证码"
        name="vcode"
        required={false}
        rules={[{ required: true, message: '请输入您的验证码' }]}
      >
        <div className="email-vcode">
          <Input autoComplete="off" placeholder="请输入您的验证码" />
          <Button
            className="email-vcode-btn"
            onClick={() => { onEmailVcode(); }}
          >
            发送邮箱验证码
          </Button>
        </div>
      </Form.Item>
      <Form.Item className="feedback">
        <Button
          type="text"
          className="feedback-button"
          onClick={() => { setisShowPop(true); }}
        >
          我要反馈
        </Button>
      </Form.Item>
      <Feedback isshow={isShowPop} setisshow={setisShowPop} />
    </>
  );
});
