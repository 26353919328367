/*
 * @Date: 2022-03-28 09:38:38
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 08:15:58
 * @Description: 功能描述
 * @FilePath: \training-platform\src\store\modal\structureManagementStore.ts
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getStuctureList,
  addSturcture,
  updateStructure,
  updateStatus,
  structureInfo,
  removeStructure,

} from '@/api/admin/structureManagement';

class StructurenManagementStore {
  // 当前分页
  page: number = 1;

  // 分页容量
  pages: number = 5;

  // 当前数据总数
  total: number = 0;

  // 当前搜索关键字
  keyWord: string = '';

  // 试题类型
  questionType: string = ''

  // 当前搜索数据状态
  status: number | undefined = undefined;

  // 当前搜索工作类别
  type: number | undefined = undefined;

  // 当前数据
  structureList: any[] = [];

  // 详情数据
  structureInfoData: any = {}

  constructor() {
    makeAutoObservable(this);
  }

  // 总分集合
  totalScoreData: any = {}

  // 获取试卷列表
  @action getStuctureList = async (data: any) => {
    const res: any = await getStuctureList(data);
    if (res.state.value === 0) {
      runInAction(() => {
        this.structureList = res.result.data;
        this.total = res.result.total;
        this.page = res.result.page;
        this.pages = res.result.pages;
      });
    }
    return res;
  }

  // 删除试卷
  @action removeStructure = async (data: any) => {
    const res: any = await removeStructure(data);
    if (res.state.value === 0) {
      runInAction(() => {
      });
    }
    return res;
  }

  // 更新状态
  @action updateStatus = async (data: any) => {
    const res: any = await updateStatus(data);
    if (res.state.value === 0) {
      runInAction(() => {
      });
    }
    return res;
  }

  // 新增
  @action addSturcture = async (data: any) => {
    const res: any = await addSturcture(data);
    if (res.state.value === 0) {
      runInAction(() => {
      });
    }
    return res;
  }

  // 试卷详情
  @action structureInfo = async (data: any) => {
    const res: any = await structureInfo(data);
    console.log(res.result);
    if (res.state.value === 0) {
      runInAction(() => {
        this.structureInfoData = res.result;
        this.totalScoreData.singletotal = Number(res.result.singleNum) * Number(res.result.singleScore);
        this.totalScoreData.multipleTotal = Number(res.result.multipleNum) * Number(res.result.multipleScore);
        this.totalScoreData.completionTotal = Number(res.result.completionNum) * Number(res.result.completionScore);
      });
    }
    return res.result;
  }

  // 新增
  @action updateStructure = async (data: any) => {
    const res: any = await updateStructure(data);
    if (res.state.value === 0) {
      runInAction(() => {
      });
    }
    return res;
  }
}

export default new StructurenManagementStore();
