/*
 * @Description: 考试须知
 * @Autor: CYF
 * @Date: 2022-04-18 16:48:01
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-19 13:45:24
 */
import {
  action,
  makeAutoObservable,
} from 'mobx';
import {
  getExamInstructions,
  saveExamInstructions,
} from '@/api/admin/examInstructions';
import { message } from 'antd';

/**
 * @description: 表示ExamInstructionsStore状态管理类
 */
class ExamInstructionsStore {
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 考试须知
   * @return {*}
   */
  @action getExamInstructions = async () => {
    const res: any = await getExamInstructions();
    if (res.state?.value === 0) {
      return res;
    }
    message.error(res.message);
    return false;
  }

  /**
   * @description: 考试须知
   * @return {*}
   */
  @action saveExamInstructions = async (data: {
    content: string
  }) => {
    const res: any = await saveExamInstructions(data);
    if (res.state?.value === 0) {
      message.success('保存成功！');
      return true;
    }
    message.error(res.message);
    return false;
  }
}

export default new ExamInstructionsStore();
