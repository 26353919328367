/**
 * 表单规则
 */
const rules = {
  phone: [
    {
      validator: (_: any, value: any) => {
        if (!value || /^1[3456789]\d{9}$/i.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请输入正确的手机号'));
      },
    },
  ],
  telephone: [
    {
      validator: (_: any, value: any) => {
        if (!value || /^((\d{3,4}-)|\d{3.4}-)?\d{7,8}$/ig.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请输入正确的座机号'));
      },
    },
  ],
  money: [
    {
      validator: (_: any, value: any) => {
        if (!value || /^(([1-9][0-9]*|0))(\.[0-9]{1,2})?$/ig.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请输入正确的数字, 最多带2位小数'));
      },
    },
  ],
  number: [
    {
      validator: (_: any, value: any) => {
        if (!value || /(^[1-9]\d*$)|(^0$)/ig.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('必须为整数'));
      },
    },
  ],
  identity: [
    {
      validator: (_: any, value: any) => {
        if (!value || /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/ig.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请输入正确的身份证号'));
      },
    },
  ],
  email: [
    {
      validator: (_: any, value: any) => {
        if (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/ig.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请输入正确的邮箱'));
      },
    },
  ],
  password: [
    {
      validator: (_: any, value: any) => {
        if (!value || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('请设置8位及以上包含大小写字母、数字的密码'));
      },
    },
  ],
  creditCode: [
    {
      validator: (_: any, value: any) => {
        if (!value || /^[\w-]{1,18}$/.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('信用代码由字符组成且不能超过18位'));
      },
    },
  ],
};

export default rules;
