import './index.less';

/**
 * 后台页面-页脚
 */
export default () => {
  const href = 'https://beian.miit.gov.cn/#/Integrated/index';
  return (
    <div className="admin-layout-footer">
      智慧职安环保科技有限公司all rights reserved
      <a href={href} target="_blank" rel="noreferrer">浙ICP备16005120号-2</a>
    </div>
  );
};
