import ajax from '@/api/ajax';
import {
  AddCourseParam,
  CommonParams, CourseData, Result, StructureList,
} from './type.d';

// 课程-查询
export const getCourseSearch = (data: CommonParams) => ajax('/v3/admin/course/item/search', data, 'GET') as Promise<Result<CourseData>>;

// 课程添加
export const postAddCourse = (data: AddCourseParam) => ajax('/v3/admin/course/item/add', data, 'POST') as Promise<Result<any>>;

// 课程-插入
export const postInsertCourse = (data: AddCourseParam) => ajax('/v3/admin/course/item/insert', data, 'POST') as Promise<Result<any>>;

// 课程-查看详情
export const getCouseDetail = (data: {
  courseItemId?: string
}) => ajax('/v3/admin/course/item/detail', data, 'GET') as Promise<Result<any>>;

// 课程-编辑-一次性编辑课程
export const postEditFullInfo = (data: AddCourseParam) => ajax('/v3/admin/course/item/editFullInfo', data, 'POST') as Promise<Result<any>>;

// 课程-删除
export const postDeleteCourse = (data: {
  id: string
}) => ajax('/v3/admin/course/item/edit/delete', data, 'POST') as Promise<Result<any>>;

// 课程-批量操作
export const postBatchOpt = (data: {
  courseItemId: string,
  type: any // （1：启用、2：禁用、3：删除）
}[]) => ajax('/v3/admin/course/item/edit/batchOpt', data, 'POST') as Promise<Result<any>>;

// 课程-编辑-主体
export const postEditCourse = (data: AddCourseParam) => ajax('/v3/admin/course/item/edit', data, 'POST') as Promise<Result<any>>;

// 课程-编辑-添加课程组
export const postAddCourseGroup = (data: {
  courseGroupId: string,
  courseItemId: string,
  pageSequence: number,
  workTypeCode: string
}) => ajax('/v3/admin/course/item/edit/addCourseGroup', data, 'POST') as Promise<Result<any>>;

// 课程-编辑-删除课程组
export const postDelCourseGroup = (data: {
  courseGroupId: string,
  courseItemId: string,
  pageSequence: number,
  workTypeCode: string
}) => ajax('/v3/admin/course/item/edit/deleteCourseGroup', data, 'POST') as Promise<Result<any>>;

// 课程-编辑-添加工作类型
export const postAddWorkType = (data: {
  courseGroupId: string,
  courseItemId: string,
  pageSequence: number,
  workTypeCode: string
}) => ajax('/v3/admin/course/item/edit/addWorkType', data, 'POST') as Promise<Result<any>>;

// 课程-编辑-删除工作类型
export const delDeleteWorkType = (data: {
  courseGroupId: string,
  courseItemId: string,
  pageSequence: number,
  workTypeCode: string
}) => ajax('/v3/admin/course/item/edit/deleteWorkType', data, 'POST') as Promise<Result<any>>;

// 课程-重新排序
export const postRearrangement = (data: {
  courseGroupId: string
  courseItemId: string
  ifNew: boolean // 是否新添加
}[]) => ajax('/v3/admin/course/item/rearrangement', data, 'POST') as Promise<Result<any>>;

// 获取工作类别
export const getWorkType = (data: {
  pcode: string
  keyword?: string
  limit?: number,
  page?: number,
  isForbidden: 1 | 0,
}) => ajax('/v3/admin/sys/param/getParamList', data, 'GET') as Promise<Result<any>>;

// 获取试卷结构列表
export const getStructureList = (data: {
  keyword: string
  limit: number
  page: number
  status: number
}) => ajax('/v3/admin/paperStructureManage/getList', data, 'GET') as Promise<Result<StructureList>>;
