/*
 * @Description: 分页器
 * @Autor: CYF
 * @Date: 2022-03-09 10:53:49
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-06 16:34:48
 */

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Pagination,
  PaginationProps,
} from 'antd';

import './index.less';

/**
 * @description: 分页器
 * @param {'default-pagination' | 'admin-pagination'} className 分页器的className 前台：default-pagination 后台：admin-pagination 弹框：pop-pagination
 * @param {PaginationProps} config 分页器配置
 */
export default (({
  className = 'default-pagination',
  config = {
    size: 'default',
    current: 1,
    pageSize: 10,
  },
}: {
  className?: 'default-pagination' | 'admin-pagination' | 'pop-pagination'
  config: PaginationProps
}) => (
  <div className={`foot-pagination-comp ${className}`}>
    <Pagination
      defaultPageSize={10} // 默认展示条数
      defaultCurrent={1} // 默认的当前页数
      showSizeChanger={className === 'admin-pagination'} // 是否展示 pageSize 切换器，当 total 大于 50 时默认为 true
      {...config} // 页面传入的配置
      showQuickJumper // 是否可以快速跳转至某页
      itemRender={(current, type, originalElement) => {
        if (type === 'prev') {
          return <b><LeftOutlined /></b>;
        }
        if (type === 'next') {
          return <b><RightOutlined /></b>;
        }
        return originalElement;
      }}// 用于自定义页码的结构，可用于优化 SEO
    />
  </div>
));
