import ajax from '../ajax';

/* 个人用户 */
// 个人用户-列表
export const getUserList = (data: any) => ajax('/v3/admin/sys/user/getUserList', data, 'GET');
// 个人用户-重置密码
export const userReset = (data: any) => ajax('/v3/admin/sys/user/resetPassword', data, 'POST');
// 个人用户-编辑/添加
export const userSave = (data: any) => ajax('/v3/admin/sys/user/saveUser', data, 'POST');
// 个人用户-删除
export const userDelete = (data: any) => ajax('/v3/admin/sys/user/del', data, 'POST');
// 个人用户-账号启用
export const userSwitch = (data: any) => ajax('/v3/admin/sys/user/forbiddenAdmin', data, 'POST');
// 个人用户-详情
export const userDetail = (data: any) => ajax('/v3/admin/sys/user/getUser', data, 'GET');
// 个人用户-培训报名列表
export const userTrainList = (data: any) => ajax('/v3/admin/sys/user/getTrainingList', data, 'GET');
// 个人用户-培训报名详情
export const userTrainDetail = (data: any) => ajax('/v3/admin/sys/user/getMyTrainingBaseInfo', data, 'GET');
// 个人用户-培训报名详情-课程列表
export const userTrainCourseList = (data: any) => ajax('/v3/admin/sys/user/getEnrollDetailList', data, 'GET');
// 个人用户-获取所有培训工作类别
export const getUserAllWorkType = (data: any) => ajax('/v3/admin/sys/user/getTrainingWorkType', data, 'GET');

/* 单位用户 */
// 单位用户-列表
export const getUnitList = (data: any) => ajax('/v3/admin/sys/corp/getUserList', data, 'GET');
// 单位用户-重置密码
export const unitReset = (data: any) => ajax('/v3/admin/sys/corp/resetPassword', data, 'POST');
// 单位用户-删除
export const unitDelete = (data: any) => ajax('/v3/admin/sys/corp/del', data, 'POST');
// 单位用户-编辑
export const unitSave = (data: any) => ajax('/v3/admin/sys/corp/saveUser', data, 'POST');
// 单位用户-账号启用
export const unitSwitch = (data: any) => ajax('/v3/admin/sys/corp/forbiddenAdmin', data, 'POST');
// 单位用户-详情
export const unitDetail = (data: any) => ajax('/v3/admin/sys/corp/getUser', data, 'GET');
// 单位用户-培训报名列表
export const unitTrainList = (data: any) => ajax('/v3/admin/sys/corp/getCropTrain', data, 'GET');
// 单位用户-培训报名详情
export const unitTrainDetail = (data: any) => ajax('/v3/admin/sys/corp/getTrainingBaseInfo', data, 'GET');
// 单位用户-培训报名详情-报名列表
export const unitTrainPostList = (data: any) => ajax('/v3/admin/sys/corp/getCropEnroll', data, 'GET');
// 单位用户-获取所有培训工作类别
export const getUnitAllWorkType = (data: any) => ajax('/v3/admin/sys/corp/getTrainingWorkType', data, 'GET');

/* 后台用户 */
// 后台用户-列表
export const getAdminList = (data: any) => ajax('/v3/admin/sys/admin/getUserList', data, 'GET');
// 后台用户-重置密码
export const adminReset = (data: any) => ajax('/v3/admin/sys/admin/resetPassword', data, 'POST');
// 后台用户-删除
export const adminDelete = (data: any) => ajax('/v3/admin/sys/admin/del', data, 'POST');
// 后台用户-新增
export const adminAdd = (data: any) => ajax('/v3/admin/sys/admin/addUser', data, 'POST');
// 后台用户-编辑
export const adminSave = (data: any) => ajax('/v3/admin/sys/admin/saveUser', data, 'POST');
// 后台用户-账号启用
export const adminSwitch = (data: any) => ajax('/v3/admin/sys/admin/forbiddenAdmin', data, 'POST');
// 后台用户-详情
export const adminDetail = (data: any) => ajax('/v3/admin/sys/admin/getUser', data, 'GET');
