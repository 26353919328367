/*
 * @Description: 数据字典
 * @Autor: CYF
 * @Date: 2022-03-29 17:29:05
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-07 10:18:00
 */
import {
  getDataDictionaryList, editSuitWork, delSuitWork, changeSuitWorkState,
} from '@/api/admin/datatDictionary';
import { message } from 'antd';
import { action, makeAutoObservable, runInAction } from 'mobx';

/**
 * @description: 表示DatatDictionaryStore状态管理类
 */
class DatatDictionaryStore {
  // 数据字典列表
  datatDictionaryList: Array<{}> = [];

  // 当前页
  activePagenum: number = 1;

  // 数据总数
  total: number = 0;

  // 表格加载
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取数据字典列表
   */
  @action getDataDictionaryList = async (data: {
    page: number;
    limit: number
  }) => {
    // 默认参数
    const defaultData = {
      page: 1,
      limit: 10,
    };
    this.loading = true;
    const res: any = await getDataDictionaryList({ ...defaultData, ...data });
    runInAction(() => {
      if (res.state?.value === 0) {
        const list = res.result?.data.map((item: any, index: number) => {
          const key = (index + 1).toString();
          return { ...{ key }, ...item };
        });
        this.datatDictionaryList = list;
        this.total = res.result?.total;
        this.activePagenum = res.result?.page;
        this.loading = false;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 新增/编辑适用工作类别
   * @param {*} data
   * @param {Function} callBack
   * @return {*}
   */
  @action editSuitWork = async (data: {}, callBack: Function) => {
    const res: any = await editSuitWork(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 删除适用工作类别
   * @param {*} data
   * @param {Function} callBack
   * @return {*}
   */
  @action delSuitWork = async (data: {
    idList: string[]
  }, callBack: Function) => {
    const res: any = await delSuitWork(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 更改适用工作类别状态
   * @param {*} data
   * @param {Function} callBack
   * @return {*}
   */
  @action changeSuitWorkState = async (data: {
    idList: string[],
    isForbidden: number
  }, callBack: Function) => {
    const res: any = await changeSuitWorkState(data);
    if (res.state?.value === 0) {
      callBack(res);
    } else {
      message.error(res.message);
    }
  };
}

export default new DatatDictionaryStore();
