import ajax from '../ajax';

// 获取证书模板列表
export const getCertificateList = (data: any) => ajax('/v3/admin/certificate/getList', data, 'GET');
// 更改证书启用状态
export const editCetStatus = (data: any) => ajax('/v3/admin/certificate/updateStatus', data, 'POST');
// 更新证书

export const updateCertificate = (data: any) => ajax('/v3/admin/certificate/update', data, 'POST');
// 查看证书详情
export const getCertificateInfo = (data: any) => ajax('/v3/admin/certificate/getPaperStructureDetail', data, 'GET');
// 删除证书
export const removeCertificate = (data: any) => ajax('/v3/admin/certificate/del', data, 'POST');

// 新增证书
export const addCertificate = (data: any) => ajax('/v3/admin/certificate/add', data, 'POST');

// 批量操作状态
export const batchStatus = (data: any) => ajax('/v3/admin/certificate/updateStatusBatch', data, 'POST');

// 获取证书生成模板
export const getTemplate = (data:any) => ajax('/v3/admin/certificate/getCertTemplateData', data, 'GET');
