/*
 * @Description: 输入下拉选择组件
 * @Autor: CYF
 * @Date: 2022-03-17 13:45:34
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-01 09:35:15
 */

import { useEffect, useState, useMemo } from 'react';
import { Empty, AutoComplete, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import { unitInfo } from '@/store/modal/registerStore';

export interface UnitMsg {
  unitId: string,
  unitName: string,
  unitCode: string,
  address: string
}

/**
 * @description: 输入下拉选择组件
 * @param {string} tipStr 输入框的提示信息
 * @param {function} getSelectResult 获取结果的函数
 * @param {object} defaultData 默认对象
 * @param {function} getDataList 请求接口函数
 * @param {Array} dataList 接口返回的列表
 * @param {number} debounceTimeout 延时时间
 */
export default observer(
  ({
    tipStr,
    getSelectResult,
    defaultData,
    getDataList,
    dataList,
    debounceTimeout,
    ifIncludeSelected = 0,
  }: {
    tipStr: String;
    getSelectResult: Function;
    defaultData: UnitMsg;
    getDataList: Function;
    dataList: Array<unitInfo>;
    debounceTimeout: number;
    ifIncludeSelected?: number
  }) => {
    const { Option } = AutoComplete;
    // 搜索条件
    const [searchKeywords, setSearchKeywords] = useState('');
    // 选中项
    const [selectVal, setselectVal] = useState<UnitMsg>();
    // 当前页
    const [activePageNum, setactivePageNum] = useState(1);
    // 控制请求
    const [requestFlag, setrequestFlag] = useState(false);
    // 加载状态
    const [fetching, setFetching] = useState(false);

    /**
     * @description: 请求回调
     * @param {any} res 请求返回对象
     * @return {Number} dataCount 数据大小
     */
    const callBack = (res: any, dataCount: number) => {
      if (res) {
        if (dataCount < res.result?.total) {
          setrequestFlag(true);
        } else {
          setrequestFlag(false);
        }
      }
    };

    /**
     * @description: 滚动加载
     * @param {any} e 事件对象
     */
    const handleScroll = (e: any) => {
      e.persist();
      const { target } = e;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight && requestFlag) {
        setactivePageNum(activePageNum + 1);
        setrequestFlag(false);
        getDataList(
          {
            page: activePageNum + 1,
            keyword: searchKeywords,
          },
          callBack,
        );
      }
    };

    /**
     * @description: 获取列表
     */
    async function handleGetDataList(val: string) {
      setSearchKeywords(val);
      getDataList({ page: 1, keyword: val, ifIncludeSelected }, callBack);
    }

    useEffect(() => {
      if (defaultData.unitName) {
        handleGetDataList('').then(() => {
          setselectVal(defaultData);
        });
      }
    }, [defaultData.unitName]);

    /**
     * @description: 下拉框搜索事件
     */
    const debounceFetcher = useMemo(() => {
      const loadOptions = (val: string) => {
        setFetching(true);
        handleGetDataList(val).then(() => {
          setFetching(false);
        });
      };
      return debounce(loadOptions, debounceTimeout);
    }, [debounceTimeout]);

    /**
     * @description: 失去焦点时的事件（失去焦点直接调用父组件获取值的函数）
     */
    const handleCurrBlur = () => {
      getSelectResult(selectVal);
    };

    /**
     * @description: 选中项改变事件
     * @param {string} value 选中的值
     * @param {any} option 选中的对象
     */
    const handleSelect = (value: string, option: any) => {
      const tmpSelectVal = {
        unitId: option.unitid,
        unitName: option.children,
        unitCode: option.unitcode,
        address: option.address,
      };
      setselectVal(tmpSelectVal);
      getSelectResult(tmpSelectVal);
    };

    /**
     * @description: 输入内容改变事件
     * @param {string} value 输入框的值
     */
    const handleChange = (value: string) => {
      setselectVal({
        unitId: '',
        unitName: value,
        unitCode: '',
        address: '',
      });
    };

    /**
     * @description: 获得焦点事件（需要调用搜索函数）
     * @param {any} e 事件对象
     */
    const handleCurrFocus = (e: any) => {
      debounceFetcher(e.target.value);
    };

    return (
      <AutoComplete
        showSearch
        placeholder={tipStr}
        defaultActiveFirstOption={false}
        filterOption={false}
        notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
        onSearch={debounceFetcher}
        onPopupScroll={handleScroll}
        onSelect={handleSelect}
        onChange={handleChange}
        onBlur={handleCurrBlur}
        onFocus={handleCurrFocus}
        value={selectVal?.unitName || ''}
      >
        {dataList
          && dataList.length
          && dataList.map((item: unitInfo) => (
            <Option
              value={item.name}
              unitid={item.id}
              unitcode={item.creditCode}
              address={item.area}
              key={item.id}
            >
              {item.name}
            </Option>
          ))}
      </AutoComplete>
    );
  },
);
