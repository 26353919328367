/*
 * @Description: 参数管理
 * @Autor: CYF
 * @Date: 2022-03-30 11:22:45
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-30 13:05:48
 */
import ajax from '../ajax';

// 获取参数列表
export const getParameterList = (data: {}) => ajax('/v3/admin/sys/setting/getSettingListData', data, 'GET');

// 新增/编辑参数
export const editParameter = (data: {}) => ajax('/v3/admin/sys/setting/saveSetting', data, 'POST');

// 删除参数
export const delParameter = (data: {}) => ajax('', data, 'POST');
