/*
 * @Description: 公共
 * @Autor: CYF
 * @Date: 2022-03-17 17:56:34
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-14 17:13:28
 */
import ajax from '../ajax';

// 获取区域列表
export const getAreaList = (data : {}) => ajax('/v2/www/api/area/getAreaList', data, 'GET');

// 获取区域详情
export const getAreaInfo = (data : {}) => ajax('/v2/www/api/area/getArea', data, 'GET');

// 获取系统参数
export const getSysParam = () => ajax('/v2/www/api/setting/getSettingList', 'GET');
