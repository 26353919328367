import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getDemoTableMock,
} from '@/api/server/demoTable';

class DemoTableStore {
  // 当前分页
  mPageNum: number = 1;

  // 当前每页展示数
  mPageSize: number = 10;

  // 当前数据总数
  mTotal: number = 0;

  // 当前数据
  mDataList: any[] = [];

  // 表格loading
  mLoading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取表格数据
   */
  @action getDataList = async (data: any) => {
    this.mLoading = true;
    const res: any = await getDemoTableMock(data);
    if (res.status === 0) {
      runInAction(() => {
        this.mDataList = res.data.list;
        this.mPageNum = res.data.pagenum;
        this.mTotal = res.data.total;
        this.mLoading = false;
      });
    }
  }
}

export default new DemoTableStore();
