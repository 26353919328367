import { createRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import './index.less';

/**
 * 搜索框组件
 * @author fxz
 * @param onChange 搜索的触发函数 接收一个string参数
 * @param defaultValue 搜索框的默认搜索值
 * @param placeholder 搜索框的placeholder值
 * @param style 搜索框的样式
 * @param skin 样式皮肤 1-前台样式 2-后台样式
 */
export default (({
  onChange,
  defaultValue = '',
  placeholder = '请输入关键字',
  style = {},
  skin = 1,
}: {
  onChange: Function,
  defaultValue?: string,
  placeholder?: string,
  style?: any,
  skin?: number,
}) => {
  const inputRef: any = createRef();

  const handles = {
    inputSearch(value: string) {
      onChange(value);
      inputRef.current.blur();
    },
  };

  return (
    <div className={skin === 1 ? 'component-searchInp' : 'component-admin-searchInp'} style={style}>
      <Input
        key={defaultValue}
        allowClear
        ref={inputRef}
        defaultValue={defaultValue}
        placeholder={placeholder}
        suffix={<SearchOutlined onClick={() => handles.inputSearch(inputRef.current.state.value)} />}
        onPressEnter={() => handles.inputSearch(inputRef.current.state.value)}
      />
    </div>
  );
});
