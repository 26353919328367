import { useState, useEffect, createRef } from 'react';
import {
  getAreaTreeList,
} from '@/api/admin/common';

import { Cascader } from 'antd';
import './index.less';

/**
 * 区域选择组件
 * @param defaultValues 初始选择的区域数组 string[]
 * @param onChange 区域选择触发函数 values 区域对应的值数组 selectedOptions 区域对应的对象数组
 * @param skin 皮肤 1-个人信息 2-后台列表
 * @param style 样式
 * @param disabled 是否禁用
 */
export default function AreaCascader({
  defaultValues = [],
  onChange = () => {},
  placeholder = '请选择区域',
  skin = 1,
  style = {},
  disabled = false,
} : {
  defaultValues?: string[],
  onChange?: Function,
  placeholder?: string,
  skin?: number,
  style?: any,
  disabled?: boolean,
}) {
  const cascaderRef: any = createRef();
  const [options, setOptions] = useState<any[]>([]);

  /**
   * 状态对应
   */
  const statusConfigs: any = {
    class: {
      1: 'component-areaCascader',
      2: 'component-areaCascader-admin',
    },
  };

  /**
   * 操作处理函数存放
   */
  const handles = {
    /**
     * 加载叶子数据
     */
    loadData(selectedOptions: any) {
      const targetOption = selectedOptions[selectedOptions.length - 1];

      targetOption.loading = true;

      getAreaTreeList({ id: targetOption.value }).then((res: any) => {
        if (res.state.value === 0) {
          const list = res.result.map((item: any) => ({ value: item.id, label: item.name, isLeaf: !item.isParent }));
          targetOption.children = list;
          targetOption.loading = false;
          setOptions([...options]);
        }
      });
    },

    /**
     * 数据选择
     */
    onChange(value: any, selectedOptions: any) {
      onChange(value, selectedOptions);
      if (value.length === 4) {
        cascaderRef.current.querySelector('.ant-cascader-picker').blur();
      }
    },

    /**
     * 选择渲染函数
     */
    valueRender(label: string[], selectedOptions: any) {
      return label.join(' ');
    },
  };
  // 初始化
  useEffect(() => {
    // 查找匹配对象的方法
    function findItem(key: string, arr: any) {
      return arr.find((item: any) => item.value === key);
    }

    // 初始获取省数据
    getAreaTreeList({ id: '0' }).then((res: any) => {
      if (res.state.value === 0) {
        const list = res.result.map((item: any) => ({ value: item.id, label: item.name, isLeaf: !item.isParent }));

        const arr = defaultValues;
        const promiseArr = arr.map((item: any) => (
          new Promise((resolve, reject) => {
            getAreaTreeList({ id: item }).then((resA: any) => {
              if (resA.state.value === 0) {
                const listA = resA.result.map((itemA: any) => ({ value: itemA.id, label: itemA.name, isLeaf: !itemA.isParent }));
                resolve(listA);
              } else {
                reject(new Error('err'));
              }
            }).catch(() => {});
          })
        ));

        // 处理获取的区域数据
        Promise.all(promiseArr).then((resB: any) => {
          const oldOptions = JSON.parse(JSON.stringify(list));
          let targetList = oldOptions;
          arr.forEach((item, index) => {
            const target = findItem(item, targetList);
            target.children = resB[index];
            targetList = target.children;
          });
          setOptions(oldOptions);
        }).catch(() => {});
      }
    });
  }, []);

  return (
    <div className={statusConfigs.class[skin]} style={style} ref={cascaderRef}>
      <Cascader
        disabled={disabled}
        changeOnSelect
        options={options}
        placeholder={placeholder}
        defaultValue={defaultValues}
        displayRender={handles.valueRender}
        loadData={handles.loadData}
        onChange={handles.onChange}
      />
    </div>
  );
}
