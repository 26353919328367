/**
 * 个人中心-我的培训
 */
import ajax from '../ajax';

// 个人用户-获取培训列表
export const getUserTrainList = (data: any) => ajax('/v2/www/personal/train/getMyTrainingList', data, 'GET');
// 个人用户-获取培训详情
export const getUserTrainDetail = (data: any) => ajax('/v2/www/personal/train/getMyTrainingBaseInfo', data, 'GET');
// 个人用户-获取培训详情-课程组信息
export const getUserTrainGroup = (data: any) => ajax('/v2/www/personal/train/lean/getCourseCataLogue', data, 'GET');
// 个人用户-获取培训详情-获取课程详情
export const getUserTrainCourseDetail = (data: any) => ajax('/v2/www/personal/train/lean/getCourseItem', data, 'GET');
// 个人用户-更新视频学习时长
export const updateCourseDuration = (data: any) => ajax('/v2/www/personal/train/lean/updateDuration', data, 'POST');
// 个人用户-各个培训状态的数量
export const getUserTrainStatus = (data: any = {}) => ajax('/v2/www/personal/train/getTrainingStatusNumList', data, 'GET');
// 个人用户-删除被退回的培训
export const deleteTrain = (data: any) => ajax('/v2/www/personal/train/delMyTraining', data, 'POST');

// 单位用户-获取培训列表
export const getUnitTrainList = (data: any) => ajax('/v2/www/crop/train/getMyTrainingList', data, 'GET');
// 单位用户-获取培训详情
export const getUnitTrainDetail = (data: any) => ajax('/v2/www/crop/train/getTrainingBaseInfo', data, 'GET');
// 单位用户-获取培训详情-培训报名清单
export const getUnitTrainEnrollList = (data: any) => ajax('/v2/www/crop/train/searchEnrollList', data, 'GET');
// 单位用户-获取培训详情-用户报名详情
export const getUnitTrainEnrollDetail = (data: any) => ajax('/v2/www/crop/train/getStaffTrainingDetail', data, 'GET');
// 单位用户-培训报名清单-退回个人用户报名
export const rejectUserEnroll = (data: any) => ajax('/v2/www/crop/train/sendBack', data, 'POST');
// 单位用户-培训报名清单-删除用户报名信息
export const deleteUserEnroll = (data: any) => ajax('/v2/www/crop/train/deleteStaff', data, 'POST');
// 单位用户-培训报名清单-批量删除用户报名信息
export const deleteUserEnrollBatch = (data: any) => ajax('/v2/www/crop/train/deleteStaffBatch', data, 'POST');
// 单位用户-各个培训状态的数量
export const getUnitTrainStatus = (data: any = {}) => ajax('/v2/www/crop/train/getTrainingStatusNumList', data, 'GET');
// 单位用户-删除培训
export const deleteUnitTrain = (data: any) => ajax('/v2/www/crop/train/cropToDeleteOneEnroll', data, 'GET');
