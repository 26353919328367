/*
 * @Date: 2022-03-14 17:16:12
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 08:10:37
 * @Description: 功能描述
 * @FilePath: \training-platform\src\api\server\messageList.ts
 */
import ajax from '../ajax';
// 获取消息列表数据
export const getMessageList = (data: any) => ajax('/v2/www/msg/getMsgList', data, 'GET');
// 移除消息
export const deleteMessage = (data: any) => ajax('/v2/www/msg/delMsg', data, 'POST');
// 消息已读
export const readMessage = (data: any) => ajax('/v2/www/msg/readMsg', data, 'POST');
// 获取消息类型列表
export const getMessageType = (data: any) => ajax('/v2/www/msg/getMsgTypeList', data, 'POST');
// 获取未读数量

export const getUnReadNum = (data: any) => ajax('/v2/www/msg/getMsgCount', data, 'POST');
