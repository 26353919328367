/*
 * @Description: 注册
 * @Autor: CYF
 * @Date: 2022-03-17 09:58:04
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-06 11:10:31
 */

import { action, makeAutoObservable, runInAction } from 'mobx';
import {
  getVerificationCode,
  registerPerson,
  registerUnit,
  getUnitList,
  feedback,
} from '@/api/server/register';
import { message } from 'antd';

export interface personUser {
  name: string,
  email: string,
  password: string,
  vcode: string,
}

export interface unitUser {
  cid?: string, // 用人单位名称
  companyName: string,
  creditCode: string,
  areaId: string,
  contactNumber: string,
  email: string,
  password: string,
  vcode: string,
  addressDetail?: string,
}

export interface unitInfo {
  id: string,
  name: string,
  creditCode?: string,
  area?: string, // 行政区划
  contact?: string,
  contactTel?: string,
  email?: string,
  address?: string, // 详细地址
  fullAddress?: string, // 完整地址
  createDate?: string,
  resource?: string,
}

/**
 * @description: 表示RegisterStore状态管理类
 */
class RegisterStore {
  // 单位列表
  dataList: string = '';

  // 列表总数
  total: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取验证码
   * @param {any} data 请求参数
   * @param {Function} callback 回调函数
   */
  @action getEmailVcode = async (data: {
    email: string
  }, callback: Function) => {
    const res: any = await getVerificationCode(data);
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 个人用户注册
   * @param {any} data 请求参数
   * @param {Function} callback 回调函数
   */
  @action registerPerson = async (data: personUser, callback: Function) => {
    const res: any = await registerPerson(data);
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 单位用户注册
   * @param {any} data 请求参数
   * @param {Function} callback 回调函数
   */
  @action registerUnit = async (data: unitUser, callback: Function) => {
    const res: any = await registerUnit(data);
    if (res.state?.value === 0) {
      await callback(res);
    } else {
      message.error(res.message);
    }
  };

  /**
   * @description: 获取单位列表
   * @param {*} data 请求参数
   * @param {Function} callback 回调函数
   * @return {*}
   */
  @action getUnitList = async (data: {
    page: number
  }, callback?: Function) => {
    const res: any = await getUnitList(data);
    runInAction(() => {
      if (res.state?.value === 0) {
        this.dataList = res.result?.data || [];
        this.total = res.result?.total;
        if (callback) {
          callback(res);
        }
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 反馈
   * @param {*} data 请求参数
   * @param {Function} callback 回调函数
   * @return {*}
   */
 @action feedback = async (data: {}, callback: Function) => {
   const res: any = await feedback(data);
   if (res.state?.value === 0) {
     callback(res);
   } else {
     message.error(res.message);
   }
 };
}

export default new RegisterStore();
