/*
 * @Description: 注册
 * @Autor: CYF
 * @Date: 2022-03-08 13:17:54
 * @LastEditors: CYF
 * @LastEditTime: 2023-09-21 17:42:50
 */
import {
  Button,
  Form, message, Tabs, Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStores } from '@/store';
import { rsaEncrypt } from '@/tools/tool';
import Person from './components/person';
import Unit from './components/unit';
import './index.less';

export default observer(() => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const location: any = useLocation();
  const history = useHistory();
  const { TabPane } = Tabs;

  const stores: any = useStores();
  const { registerStore, loginStore } = stores;
  const {
    getEmailVcode,
    registerPerson,
    registerUnit,
  } = registerStore;
  const {
    getPublicKey,
  } = loginStore;

  // 当前tab的key
  const [activeKey, setactiveKey] = useState(location.state?.activeKey || 'person');
  // 注册按钮是否可点击
  const [isActive, setisActive] = useState(false);
  const [cid, setcid] = useState('');

  // 注册框显示数据列表
  const formData = [
    {
      tabname: '个人用户注册',
      key: 'person',
      name: 'person',
      register: '/',
    },
    {
      tabname: '单位用户注册',
      key: 'unit',
      name: 'unit',
      register: '/',
    },
  ];

  /**
   * @description: tab改变事件
   * @param {string} key
   * @return {*}
   */
  const onTabChange = (key: string) => {
    setactiveKey(key);
    form.resetFields();
    if (key === 'unit') {
      form.setFieldsValue({
        name: 'default',
      });
    } else if (key === 'person') {
      form.setFieldsValue({
        companyName: 'default',
        creditCode: 'default',
        areaId: 'default',
        contactNumber: '15000000000', // 用default过不了校验规则
      });
    }
  };

  /**
   * @description: 获取验证码事件
   */
  const onEmailVcode = () => {
    const emailVal = form.getFieldValue('email');
    if (!emailVal) {
      message.warning('请正确输入您的邮箱地址');
      return false;
    }
    const data = {
      email: emailVal,
    };
    getEmailVcode(JSON.parse(JSON.stringify(data)), () => {
      message.success('发送验证码成功,请至邮箱查收');
    });
  };

  /**
   * @description: 请求成功回调
   * @param {any} res 请求返回的对象
   * @return {*}
   */
  const callBack = (res: any) => {
    if (res) {
      message.success('注册成功，去登录~', undefined, () => {
        history.push({
          pathname: '/login',
          state: { activeKey },
        });
      });
    }
  };

  /**
   * @description: 注册-请求
   * @param {any} fieldsValue
   * @param {any} key
   * @return {*}
   */
  const reqRegister = (fieldsValue: any, key: any, randomKey: any) => {
    if (activeKey === 'person') {
      // 请求参数
      const values = {
        // ...fieldsValue,
        name: fieldsValue.name,
        email: fieldsValue.email,
        password: rsaEncrypt(fieldsValue.password, key),
        vcode: fieldsValue.vcode,
        uuid: randomKey,
      };
      // 个人用户注册
      registerPerson(values, callBack);
    } else {
      // 请求参数
      const values = {
        // ...fieldsValue,
        cid,
        companyName: fieldsValue.companyName,
        creditCode: fieldsValue.creditCode,
        areaId: fieldsValue.areaId,
        contactNumber: fieldsValue.contactNumber,
        email: fieldsValue.email,
        password: rsaEncrypt(fieldsValue.password, key),
        vcode: fieldsValue.vcode,
        uuid: randomKey,
      };
      // 单位用户注册
      registerUnit(values, callBack);
    }
  };

  /**
   * @description: 注册按钮点击事件
   */
  const onFinish = () => {
    // 表单校验
    form
      .validateFields()
      .then((fieldsValue) => {
        if (fieldsValue.password !== fieldsValue.repeatPassword) {
          message.warning('确认密码与设置密码不一致,请重新输入');
          return false;
        }
        getPublicKey().then((res: any) => {
          if (res) {
            if (res?.result) {
              reqRegister(fieldsValue, res.result, res.key);
              return false;
            }
            message.error(res.message);
          }
        });
      })
      .catch(() => {});
  };

  return (
    <div className="register-page">
      <Title level={3}>用户注册</Title>
      <Tabs activeKey={activeKey} onChange={onTabChange}>
        {formData.map((item) => (
          <TabPane tab={item.tabname} key={item.key}>
            <Form
              form={form}
              className="register-form"
              layout="vertical"
              name={item.name} // 表单名称，会作为表单字段 id 前缀使用
              scrollToFirstError // 提交失败自动滚动到第一个错误字段
              onFinish={onFinish} // 提交表单且数据验证成功后回调事件
              onFinishFailed={
                (errorInfo) => { console.log('Failed:', errorInfo); }
              } // 提交表单且数据验证失败后回调事件
              onValuesChange={(changedValues, allValues) => {
                let tmpisActive = true;
                Object.keys(allValues).forEach((key) => {
                  if (!allValues[key]) {
                    tmpisActive = false;
                  }
                });
                setisActive(tmpisActive);
              }} // 字段值更新时触发回调事件
            >
              {item.key === 'person' ? (
                <Person onEmailVcode={onEmailVcode} />
              ) : (
                <Unit
                  onEmailVcode={onEmailVcode}
                  form={form}
                  setisActive={setisActive}
                  setcid={setcid}
                />
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className={isActive ? 'register-form-button active' : 'register-form-button'}
                  disabled={!isActive}
                >
                  立即注册
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
});
