/* eslint-disable lines-between-class-members */
import { getTrainingCourse, getTrainingCourseGroup, getTrainingDetail } from '@/api/server/trainingManagement';
import {
  getCropTrainingCourse,
  getCropTrainingDetail,
  postCollSignUp,
  postDeleteBatch, postDeleteOne, postEditStaffInfo, postSaveDraftInfoList, postSearch,
  postChangeReturned,
} from '@/api/server/trainingManagement/crop';
import {
  Draft,
} from '@/api/server/trainingManagement/type';
import { message, Modal } from 'antd';
import {
  makeAutoObservable,
  runInAction,
} from 'mobx';
import { getPersonalTrainingCourse, getPersonalTrainingCourseGroup, getPersonalTrainingDetail } from '@/api/server/trainingManagement/personal';

class OnlineTrainingStore {
  /** 用户类别 */
  userType: string | null = localStorage.getItem('userType');
  /** 行政区划id */
  areaId?: string

  /** 当前关键词 */
  keyword: string = '';
  setKeyword = (val: string) => { this.keyword = val; };

  current: number = 1; // 当前分页
  pageSize: number = 9; // 当前每页展示数
  total: number = 0; // 当前数据总数

  // 分页方法
  onChange = (c: number, p: number = this.pageSize) => {
    let toCurrent = c <= 0 ? 1 : c;
    const toPageSize = p <= 0 ? 1 : p;
    const tempTotalPage = Math.ceil(this.total / toPageSize);
    if (toCurrent > tempTotalPage) {
      toCurrent = Math.max(1, tempTotalPage);
    }
    this.current = toCurrent;
    if (toCurrent === 1) {
      this.pageSize = 8;
    } else {
      this.pageSize = 9;
    }
  };

  /** 员工列表 */
  Croplist: any[] = [];
  // 网络请求等待
  isLoading: boolean = false;
  tid?: string;
  setTid = (tid: string) => { this.tid = tid; };

  /** 请求----获取培训基本信息和课程组 */
  getTraining = () => {
    let promise1: Promise<any>;
    let promise2: Promise<any>;
    const { tid } = this;
    switch (this.userType) {
      case '1':
        promise1 = getPersonalTrainingCourseGroup({ tid });
        promise2 = getPersonalTrainingDetail({ tid });
        break;
      case '2':
        promise1 = getTrainingCourseGroup({ tid });
        promise2 = getCropTrainingDetail({ trainingId: tid });
        break;
      default:
        promise1 = getTrainingCourseGroup({ tid });
        promise2 = getTrainingDetail({ trainingId: tid });
        break;
    }

    return Promise.all([promise1, promise2]);
  };

  getTrainingCourse = (courseGroupId: string, trainCourseGroupId: string) => {
    switch (this.userType) {
      case '1':
        return getPersonalTrainingCourse({ courseGroupId, tid: this.tid });
      case '2':
        return getCropTrainingCourse({ trainCourseGroupId, tid: this.tid });
      default:
        return getTrainingCourse({ courseGroupId, tid: this.tid });
    }
  }

  /** 请求----获取员工列表 */
  getData = () => {
    this.isLoading = true;
    postSearch({
      page: this.current,
      limit: this.pageSize,
      keyword: this.keyword,
      tid: this.tid,
    }).then((res: any) => {
      if (res.state.value === 0) {
        runInAction(() => {
          this.isLoading = false;
          this.total = res.result.total;
          this.Croplist = res.result.data.map((item: any) => ({ ...item, checked: false }));
        });
      }
    });
  };

  /** 控制单位培训草稿弹窗显示 */
  isCropModalVisible: boolean = false
  setCropModal = (type: boolean, userInfo?: any) => {
    this.isCropModalVisible = type;
    this.userInfo = userInfo;
  }
  /** 当前员工表报名表id */
  enrollDetailId?: string;
  setEnrollDetailId = (val: string) => { this.enrollDetailId = val; };
  /** 当前员工存储员工信息 */
  userInfo?: any;
  setUserInfo = (vals: any) => { this.userInfo = vals; };

  /** 请求----删除一个员工草稿信息  */
  delOnePersonal = (id: string) => {
    postDeleteOne({ enrollDetailId: id }).then((res) => {
      if (res.state.value === 0) {
        message.success('成功删除该名员工草稿信息');
        runInAction(() => {
          this.getData();
        });
      }
    });
  }

  /** 请求----存储一个员工草稿信息 */
  saveDraftInfo = (data: Draft) => {
    postEditStaffInfo({
      email: data.email,
      idcard: data.idCard,
      name: data.uname,
      enrollDetailId: this.userInfo.enrollDetailId,
    }).then((res) => {
      if (res.state.value === 0) {
        runInAction(() => {
          message.success('成功编辑员工草稿信息');
          this.isCropModalVisible = false;
          this.getData();
        });
      } else {
        message.info(res.message);
      }
    });
  }

  /** 请求----存储多名员工草稿信息 */
  saveDraftInfoList = (data: Draft[]) => {
    postSaveDraftInfoList(data).then((res) => {
      if (res.state.value === 0) {
        message.success('成功存储员工草稿信息');
        runInAction(() => {
          this.isCropModalVisible = false;
          this.getData();
        });
      } else {
        message.info(res.message);
      }
    });
  }

  /** 请求----批量删除员工草稿信息 */
  delBatchDraft = (list: string[]) => {
    postDeleteBatch({ enrollDetailIds: list }).then((res) => {
      if (res.state.value === 0) {
        message.success('成功删除草稿信息');
        runInAction(() => {
          this.getData();
        });
      }
    });
  }

  /** 请求----员工报名 */
  collSignUp = (list: string[], fn: () => void) => {
    let temp;
    if (list.length === 0) {
      message.info('对不起，您没有选中报名成员!');
    } else {
      temp = list.map((item) => ({ enrollDetailId: item }));
      postCollSignUp(temp).then((res) => {
        if (res.state.value === 0) {
          Modal.success({
            content: '您已报名成功，请贵单位提醒报名清单中受训人在培训时间开始后前往本平台进行登录学习和在线考试，账号为其个人邮箱或身份证号，密码为默认密码：Zyj123456！',
            okText: '确定',
            onOk: fn,
          });
        }
      });
    }
  }

  /** 请求----单位退回转草稿 */
  changeReturned = async (enrollId: string) => {
    const res = await postChangeReturned({ enrollId });
    if (res.state.value === 0) {
      return true;
    }
    message.error('退回培训报名人员信息获取失败!');
    return false;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new OnlineTrainingStore();
