/*
 * @Date: 2022-03-14 17:19:34
 * @LastEditors: shencl
 * @LastEditTime: 2022-03-28 08:45:57
 * @Description: 功能描述
 * @FilePath: \training-platform\src\store\modal\messageListStore.ts
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';

import {
  getMessageList, deleteMessage, readMessage, getMessageType, getUnReadNum,
} from '@/api/server/messageList';

class MessageListStore {
  // 当前每页展示数
  pageSize: number = 0;

  // 当前数据总数
  total: number = 0;

  // 当前页
  pageNum: number=0

  // 行政区域Id
 areaid: number=0;

 // 关键字
  keyword:string=''

  // 总页数
  pages:string=''

  // 当前数据
  mmessageList: any[] = [];

  // 消息类型列表
  messageTypeLit:any[]=[];

  // 所有消息列表
  allMessageList:any[]=[];

  // 未读数量
  unReadNum:number=0

  constructor() {
    makeAutoObservable(this);
  }

  // 获取消息列表
  @action getMessageList = async (data: any) => {
    const res: any = await getMessageList(data);
    if (res.state?.value === 0) {
      this.pageNum = res.result.page;
      this.pageSize = res.result.pages;

      this.mmessageList = res.result.data;

      return res;
    }
    return false;
  }

  // // 移除消息
  @action deleteMessage = async (data: any) => {
    const res: any = await deleteMessage(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  }

  // 消息已读
  @action readMessage = async (data: any) => {
    const res: any = await readMessage(data);
    if (res.state.value === 0) {
      return res;
    }
    return false;
  }

  // 获取消息类型列表
  @action getMessageType = async (data: any) => {
    const res: any = await getMessageType(data);
    if (res.state.value === 0) {
      this.messageTypeLit = res.result;
      return this.messageTypeLit;
    }
    return false;
  }

  // 获取未读消息数量
  // 获取消息列表
  @action getUnReadNum= async (data: any) => {
    const res: any = await getUnReadNum(data);
    if (res.state?.value === 0) {
      this.unReadNum = res.result.unreadNum;
      this.total = res.result.total;

      return res;
    }
  }
}

export default new MessageListStore();
