const apiPath = {
  development: '', // 开发环境api地址
  production: 'http://192.168.1.74:8000', // 生产环境api地址
};

const uploadApis = '/v2/admin/uploadFile';
const serverUploadApi = '/v2/www/api/file/upload';
const adminUploadApi = '/v3/admin/api/file/upload';

export {
  apiPath,
  uploadApis,
  serverUploadApi,
  adminUploadApi,
};
