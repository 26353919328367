import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale/zh_CN';
import { stores } from './store';
import reportWebVitals from './reportWebVitals';
import Router from './router';

import 'antd/dist/antd.less';
import '@/assets/css/server.less';
import '@/assets/css/admin.less';

import './index.css';

moment.locale('zh-cn');

ReactDOM.render(
  <Provider {...stores}>
    <ConfigProvider locale={zhCN}>
      <Router />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
