/*
 * @Description: 修改密码
 * @Autor: CYF
 * @Date: 2022-03-25 08:33:02
 * @LastEditors: CYF
 * @LastEditTime: 2023-10-08 13:50:22
 */
/**
 * @description: 重置密码弹框
 * @param {boolean} isModalVisible 是否显示弹框
 * @param {Function} setisModalVisible 设置是否显示弹框
 * @param {boolean} defaultIsChangePassword 默认是否显示修改密码
 * @param {Function} callback 修改密码成功后的回调函数
 */

import { useStores } from '@/store';
import {
  Button, Form, Input, message,
} from 'antd';
import CustomPopover from '@/components/CustomPopover';
import rules from '@/configs/formRules';
import { rsaEncrypt } from '@/tools/tool';

/**
 * @param mode 1-前台修改密码 2-后台修改密码
 */
export default (({
  isModalVisible,
  setisModalVisible,
  mode = 1,
}: {
  isModalVisible: boolean,
  setisModalVisible: Function,
  mode?: number,
}) => {
  const [form] = Form.useForm();
  const stores: any = useStores();
  const { loginStore } = stores;
  const {
    getPublicKey,
    resetPasswordByOld,
    resetAdminPassWordByOld,
  } = loginStore;

  /**
   * @description: 重置密码-密码-请求
   * @param {any} fieldsValue
   * @param {any} key
   * @return {*}
   */
  const reqResetPassword = (fieldsValue: any, key: any) => {
    const data = {
      oldPassword: rsaEncrypt(fieldsValue.oldPassword, key),
      newPassword: rsaEncrypt(fieldsValue.password, key),
    };
    if (mode === 1) {
      resetPasswordByOld(JSON.parse(JSON.stringify(data)), (res: any) => {
        message.success('本账号登录密码修改成功');
        setisModalVisible(false);
        // if (res?.result) {
        //   message.success('本账号登录密码修改成功');
        //   setisModalVisible(false);
        // } else if (res?.result === false) {
        //   message.error('重置密码失败，请稍后再试');
        // } else {
        //   message.error(res?.message);
        // }
      });
    }
    if (mode === 2) {
      resetAdminPassWordByOld(data, (res: any) => {
        message.success('本账号登录密码修改成功');
        setisModalVisible(false);
      });
    }
  };

  /**
   * @description: 修改密码确定修改按钮点击事件
   */
  const onResetPassword = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        if (fieldsValue.password !== fieldsValue.repeatPassword) {
          message.warning('确认密码与新密码不一致,请重新输入');
          return false;
        }
        getPublicKey().then((res: any) => {
          if (res) {
            if (res?.result) {
              reqResetPassword(fieldsValue, res.result);
              return false;
            }
            message.error(res.message);
          }
        });
      })
      .catch(() => { });
  };

  return (
    <CustomPopover
      title="修改密码"
      className="reset-password-pop"
      isshow={isModalVisible}
      setisshow={setisModalVisible}
      customConfig={{
        footer: [
          <Button key="back" onClick={() => { setisModalVisible(false); }}>
            取消
          </Button>,
          <Button key="submit" type="primary" onClick={onResetPassword}>
            确定修改
          </Button>,
        ],
        onCancel: () => { setisModalVisible(false); },
        onOk: onResetPassword,
      }}
    >
      <Form
        form={form}
        className="reset-password-form"
        layout="vertical"
        name="checkEmail" // 表单名称，会作为表单字段 id 前缀使用
        scrollToFirstError
        preserve={false}
      >
        <Form.Item
          label="原密码"
          name="oldPassword"
          required={false}
          rules={[{ required: true, message: '请输入原密码' }]}
        >
          <Input.Password placeholder="请输入原密码" />
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          required={false}
          rules={[...rules.password, { required: true, message: '请输入新密码' }]}
        >
          <Input.Password placeholder="请输入新密码（8位及以上包含大小写字母、数字）" />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="repeatPassword"
          required={false}
          rules={[{ required: true, message: '请再次输入新密码' }]}
        >
          <Input.Password placeholder="请再次输入新密码" />
        </Form.Item>
      </Form>
    </CustomPopover>
  );
});
