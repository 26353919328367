/*
 * @Description: 首页
 * @Autor: CYF
 * @Date: 2022-03-23 09:03:46
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-31 08:50:23
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getBanner,
  getNews,
  getContactUs,
} from '@/api/server/home';
import { message } from 'antd';

export interface newsItem {
  id: string,
  imageUrl: string,
  title: string,
  updateDate: string,
  summary: string,
}

/**
 * @description: 表示HomeStore状态管理类
 */
class HomeStore {
  // 轮播图列表
  bannerList: Array<newsItem> = [];

  // 资讯动态列表
  newsList: Object = {};

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @description: 获取轮播图列表
   */
  @action getBannerList = async () => {
    const res: any = await getBanner();
    runInAction(() => {
      if (res.state?.value === 0) {
        this.bannerList = res.result;
      } else {
        message.error(res.message);
      }
    });
  };

  /**
   * @description: 获取资讯动态列表
   */
  @action getNewsList = async (data: {}, callback: Function) => {
    const res: any = await getNews();
    runInAction(() => {
      if (res.state?.value === 0) {
        this.newsList = res.result;
        callback(res.result);
      } else {
        message.error('');
      }
    });
  };

  /**
   * @description: 获取联系我们
   */
  @action getContactUs = async (data: {}, callback: Function) => {
    const res: any = await getContactUs();
    if (res.status === 0) {
      callback(res);
    } else {
      message.error('');
    }
  };
}

export default new HomeStore();
