import React from 'react';

const storeFiles = require.context('./modal', true, /\.ts$/);

const map: { [key: string]: string } = {};

storeFiles.keys().forEach((key) => {
  const keyArr = key.split('/');
  keyArr.shift();
  map[keyArr.join('.').replace(/\.ts$/g, '')] = storeFiles(key).default;
});

const storesContext = React.createContext({
  ...map,
});

const useStores = () => React.useContext(storesContext);

export { map as stores, useStores };
