/* eslint-disable no-return-assign */
/* eslint-disable lines-between-class-members */
import { getTrainingList, getPersonalTrainingList, getTrainingWorkType } from '@/api/server/trainingManagement';
import { getCropTrainingList } from '@/api/server/trainingManagement/crop';
import { Params, Result, TrainingListData } from '@/api/server/trainingManagement/type';
import { message } from 'antd';
import {
  makeAutoObservable,
  runInAction,
} from 'mobx';

class OnlineTrainingStore {
  /** 用户类别 */
  userType: string | null = localStorage.getItem('userType');
  /** 行政区划id */
  areaId?: string

  /** 当前所选培训ID */
  trainingId?: string

  /** 当前关键词 */
  keyword?: string;
  /** 适用工作类别 */
  workType?: string;
  /** 当前选择时间段 */
  time: [string, string] = ['', ''];

  current: number = 1; // 当前分页
  pageSize: number = 12; // 当前每页展示数
  total: number = 0; // 当前数据总数
  /** 当前数据 */
  list: any[] = [];

  // 表格loading
  isLoading: boolean = false;
  /** 获取首页培训管理列表 */
  getDataList = async (current: number = this.current) => {
    if (this.isLoading) return;
    this.isLoading = true;
    const data: Params = {
      beginDate: this.time[0],
      endDate: this.time[1],
      limit: this.pageSize,
      page: current,
      keyword: this.keyword,
      workType: this.workType,
    };
    let res: Result<TrainingListData>;
    switch (this.userType) {
      case '1':
        res = await getPersonalTrainingList(data);
        break;
      case '2':
        res = await getCropTrainingList(data);
        break;
      default:
        res = await getTrainingList(data);
    }
    this.isLoading = false;
    if (res.state.value === 0) {
      const temp1 = res.result.data
        .filter((item) => item.signEndTime >= Date.now())
        .sort((a, b) => {
          if (a.isEnroll === b.isEnroll) {
            return b.signEndTime - a.signEndTime;
          }
          return a.isEnroll - b.isEnroll;
        });
      const temp2 = res.result.data.filter((item) => item.signEndTime < Date.now()).sort((a, b) => b.signEndTime - a.signEndTime);
      runInAction(() => {
        this.list = [...temp1, ...temp2];
        this.current = current;
        this.total = res.result.total;
        this.isLoading = false;
      });
    } else {
      message.info(res.message, 6);
    }
  }

  /** 修改筛选条件信息 */
  changeInfo = (type: number, data: any) => {
    this.current = 1;
    switch (type) {
      case 0:
        this.keyword = data;
        break;
      case 1:
        this.workType = data;
        break;
      case 2:
        this.time = data;
        break;
      default:
        break;
    }
  }

  workTypeList: {
    label: string,
    value: string
  }[] = []
  // 获取工作类别列表
  getWorkTypeList = () => {
    getTrainingWorkType().then((res) => {
      if (res.state.value === 0) {
        const temp = res.result.map((item) => ({
          label: item.workTypeName,
          value: item.workTypeCode,
        }));
        runInAction(() => {
          this.workTypeList = temp ?? [];
        });
      }
    });
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new OnlineTrainingStore();
