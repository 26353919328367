import moment from 'moment';
/**
 * 节流函数
 * @param {*} object 参数对象
 * @param {function} object.fn 处理函数
 * @param {number} object.delay 节流时间 默认500ms
 */
const noel_throttle = function ({ fn, delay = 500 } = {}) {
  let isActive = true;

  return function(...rest) {
    let content = this;
    let args = rest;

    if (isActive) {
      fn && fn.apply(content, args);
      isActive = false;
      setTimeout(function() {
        isActive = true;
      }, delay);
    }
  }
}

/**
 * 防抖函数
 * @param fn 处理函数
 * @param delay 防抖时间 默认500ms
 * @param immediate 首次是否立即执行
 */
const noel_debounce = function({ fn, delay = 500, immediate = false } = {}) {
  let timer = null;

  return function(...rest) {
    let context = this;
    let args = rest;

    clearTimeout(timer);

    if(immediate && timer === null) {
      fn.apply(context, args);
      timer = 0;
      return;
    }
    timer = setTimeout(function() {
      fn.apply(context, args);
      timer = null;
    }, delay);
  }
}

/**
 * 容器滚动冒泡禁止
 * @param {*} object 参数对象
 * @param {string} object.cssQuery 对象元素查询css
 * @param {boolean} object.bind true:绑定事件 false: 取消绑定事件
 * @param {boolean} [object.isAntd = true] 是否是antd下拉选择
 */
const scrollPrevent = function ({
  cssQuery,
  bind,
  isAntd = true
}) {
  const elems = [...document.body.querySelectorAll(cssQuery)];
  function prevent(e) {
    if (!isAntd) {
      const clientHeight = this.clientHeight; // 视图高度
      const scrollHeight = this.scrollHeight; // 容器实际高度
      const scrollTop = this.scrollTop; // Y轴滚动距离
      const isDown = e.deltaY > 0; // 是否是向下滚动

      if (clientHeight + scrollTop >= scrollHeight && isDown) {
        e.preventDefault();
      }
      if (scrollTop <= 0 && !isDown) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
    e.stopPropagation();
  }

  if (bind) {
    elems.forEach((item) => {
      item.addEventListener('mousewheel', prevent, false);
    });
  } else {
    elems.forEach((item) => {
      item.removeEventListener('mousewheel', prevent, false);
    });
  }
};

/**
 * 时间戳格式转换
 * @param {*} time 时间戳
 * @param {*} type 时间格式： 0：'Y-MM-DD', 1: 'Y-MM-DD HH:mm:ss', 2: 'Y-MM-DD HH:mm'
 * @returns
 */
const norTimeStr = (time, type = 0) => {
  switch (type) {
    case 0:
      return moment(time).format('Y-MM-DD');
      break;
    case 1:
      return moment(time).format('Y-MM-DD HH:mm:ss');
      break;
    case 2:
      return moment(time).format('Y-MM-DD HH:mm');
      break;
    default:
      return moment(time).format('Y-MM-DD');
      break;
  }
};

// 获取url后参数
const getSearchUrl = () => {
  const url = decodeURI(window.location.href);
  const search = url.split('?')[1];
  const searchArr = search ? search.split('&') : [];
  const searchObj = {};
  searchArr.forEach((item) => {
    const arr = item.split('=');
    searchObj[arr[0]] = arr[1];
  })
  return searchObj;
};

/**
 * 数组分组构成新数组
 * @param {Array<any>} array 传入的数组
 * @param {number} subGroupLength 新数组中每组数组的长度
 * @returns 新数组
 */
 const groupArray = (array, subGroupLength) => {
  let index = 0;
  const newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}

/**
 * 解析返回的地区数据, 返回解析的数组
 * @param data 地址数据
 * @param type 1-数据id数组 2-数据名称数组
 */
const formatArea = (data, type = 1) => {
  const arr = [];
  const arrStr = [];
  if (data.provinceId) {
    arr.push(data.provinceId);
  }
  if (data.cityId) {
    arr.push(data.cityId);
  }
  if (data.areaId) {
    arr.push(data.areaId);
  }
  if (data.streetId) {
    arr.push(data.streetId);
  }

  if (data.provinceName) {
    arrStr.push(data.provinceName);
  }
  if (data.cityName) {
    arrStr.push(data.cityName);
  }
  if (data.areaName) {
    arrStr.push(data.areaName);
  }
  if (data.streetName) {
    arrStr.push(data.streetName);
  }
  
  if (type === 1) {
    return arr;
  }
  return arrStr;
}

/**
 * @description: 替换字符串中的html标签
 * @param {string} str 传入的需要处理的字符串
 * @return {string} 处理后的字符串
 */
const replaceStrLabel = (str) => {
  if (str) {
    return str.replace(/(<([^>]+)>)/ig, "")
  }
  return ''
}

/**
 * 判断培训信息是否可以退回
 * @param studiedProgress 线上学习-已学习数
 * @param allCourseNum 线上学习-总数
 */
 const judgeCanRject = ({
  studiedProgress,
  allCourseNum,
}) => {
  /* 学习进度一半及以下 */
  if (studiedProgress * 2 <= allCourseNum) {
    return true;
  }
  /* 超级管理员 */
  if (localStorage.getItem('roles')?.includes('superadmin')) {
    return true;
  }
  return false;
};

export {
  noel_throttle,
  scrollPrevent,
  noel_debounce,
  norTimeStr,
  getSearchUrl,
  groupArray,
  formatArea,
  replaceStrLabel,
  judgeCanRject,
}
