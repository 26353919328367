import {
  action,
} from 'mobx';
import { getAreaList, getAreaInfo } from '@/api/server/public';

// 表示MultiSelectStore状态管理的类
class MultiSelectStore {
  /**
   * @description: 根据父id获取行政区划列表
   * @param {*} data
   * @return {*}
   */
  @action getAreaList = async (data: {
    pid: string
  }, callback: Function) => {
    const res: any = await getAreaList(data);
    if (res.state?.value === 0) {
      callback(res);
    }
  }

  /**
   * @description: 根据id获取行政区划详情
   * @param {*} data
   * @return {*}
   */
  @action getAreaInfo = async (data: {
    id: string
  }, callback: Function) => {
    const res: any = await getAreaInfo(data);
    if (res.state?.value === 0) {
      callback(res);
    }
  }
}
export default new MultiSelectStore();
