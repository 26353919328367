/*
 * @Description: 数据字典
 * @Autor: CYF
 * @Date: 2022-03-29 17:27:22
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-30 10:36:57
 */
import ajax from '../ajax';

// 获取数据字典列表
export const getDataDictionaryList = (data: {}) => ajax('/v3/admin/sys/param/getParamListData', data, 'GET');

// 新增/编辑适用工作类别
export const editSuitWork = (data: {}) => ajax('/v3/admin/sys/param/saveParam', data, 'POST');

// 删除适用工作类别
export const delSuitWork = (data: {}) => ajax('/v3/admin/sys/param/del', data, 'POST');

// 更改适用工作类别状态
export const changeSuitWorkState = (data: {}) => ajax('/v3/admin/sys/param/forbiddenParam', data, 'POST');
