/*
 * @Date: 2022-03-28 09:38:38
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 08:14:49
 * @Description: 功能描述
 * @FilePath: \training-platform\src\store\modal\questionManagementStore.ts
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getQuestionList,
  removeQuestion,
  editQuestion,
  addQuestion,
  getQuestionInfo,
  ImportQuestion,
} from '@/api/admin/questionManagement';

import { getAllWorkType } from '@/api/admin/common';

class QuestionManagementStore {
  // 当前页码
  page: number = 1;

  // 总页数
 pages:number=0;

  // 当前数据总数
  total: number = 0;

  // 当前搜索关键字
  keyWord: string = '';

  // 试题类型
  questionType:string=''

  // 当前搜索数据状态
  status: number|undefined = undefined;

  // 当前搜索工作类别
  type: number|undefined = undefined;

  // 当前数据
  questionList: any[] = [];

  // 工作类别
  workTypeList=[]

  // 导入地址
  fileUploadUrl:string='/v3/admin/questionManage/questionsImport'

  /**
   * 获取试题列表
   */
  @action getQuestionList = async (data: any) => {
    const res: any = await getQuestionList(data);
    runInAction(() => {
      if (res.state.value === 0) {
        this.page = res.result.page;
        this.questionList = res.result.data;
        this.total = res.result.total;
      }
    });
    return res;
  }

  // 移除试题
@action removeQuestion = async (data: any) => {
  const res: any = await removeQuestion(data);
  if (res.state.value === 0) {
    return true;
  }
  return false;
}

// 导入试题

@action ImportQuestion = async (data: any) => {
  const res: any = await ImportQuestion(data);
  console.log(res);
  if (res.state.value === 0) {
    return true;
  }
  return false;
}

// 新增试题
@action addQuestion = async (data: any) => {
  const res: any = await addQuestion(data);
  if (res.state.value === 0) {
    return true;
  }
  return false;
}

// 查看试题详情
@action getQuestionInfo = async (data: any) => {
  const res: any = await getQuestionInfo(data);
  if (res.state.value === 0) {
    return res.result;
  }
}

// 修改试题
@action editQuestion = async (data: any) => {
  const res: any = await editQuestion(data);
  if (res.state.value === 0) {
    return res;
  }
}

// 获取工作类别
@action getWorkType = async (data:any) => {
  const res:any = await getAllWorkType(data);
  if (res.state.value === 0) {
    const temp = res.result.map((item:any) => ({
      label: item.name,
      value: item.code,
    }));
    console.log(temp);
    runInAction(() => {
      this.workTypeList = temp;
    });
  }
  return this.workTypeList;
}

constructor() {
  makeAutoObservable(this);
}
}

export default new QuestionManagementStore();
