/*
 * @Description: 首页
 * @Autor: CYF
 * @Date: 2022-03-23 09:02:15
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-31 08:21:25
 */
import ajax from '../ajax';

// 获取banner列表
export const getBanner = () => ajax('/v2/www/api/article/getHomeArticleList', 'GET');

// 获取资讯动态
export const getNews = () => ajax('/v2/www/api/article/getHomeArticleList2', 'GET');

// 获取联系我们
export const getContactUs = () => ajax('', 'GET');
