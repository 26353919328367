/*
 * @Descripttion:
 * @version:
 * @Author: Fanzy
 * @Date: 2022-03-08 16:57:23
 * @LastEditors: Fanzy
 * @LastEditTime: 2022-03-11 09:16:28
 */
import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getDemoDataList,
  getListInfo,
} from '@/api/server/demoList';

function group(array: Array<any>, subGroupLength: number) {
  let index = 0;
  const newArray = [];
  while (index < array.length) {
    newArray.push(array.slice(index, index += subGroupLength));
  }
  return newArray;
}

class DemoListStore {
  list: any[] = [];

  newList: any[] = [];

  listInfo: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取列表数据
   */

  @action getDemoList = async (data: any) => {
    const res: any = await getDemoDataList(data);
    runInAction(() => {
      this.list = res.list;
      this.newList = group(this.list, 3);
      console.log(this.newList);
    });
  }

  /**
   * 获取文章详情
   */

     @action getListInfo = async (data: any) => {
       const res: any = await getListInfo(data);
       runInAction(() => {
         this.listInfo = res.data;
       });
     }
}

export default new DemoListStore();
