/*
 * @Description: 考试须知
 * @Autor: CYF
 * @Date: 2022-04-18 16:55:42
 * @LastEditors: CYF
 * @LastEditTime: 2022-04-19 13:44:12
 */
import ajax from '../ajax';

// 获取考试须知
export const getExamInstructions = () => ajax('/v3/admin/paperInstructions/getInstruction', 'GET');

// 保存考试须知
export const saveExamInstructions = (data: { content: string }) => ajax('/v3/admin/paperInstructions/saveInstruction', data, 'POST');
