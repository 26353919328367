/*
 * @Date: 2022-03-28 09:33:30
 * @LastEditors: CYF
 * @LastEditTime: 2022-03-29 08:08:43
 * @Description: 功能描述
 * @FilePath: \training-platform\src\api\admin\questionManagement.ts
 */
import ajax from '../ajax';

// 获取试题
export const getQuestionList = (data: any) => ajax('/v3/admin/questionManage/getQuestionList', data, 'GET');
// 删除试题
export const removeQuestion = (data: any) => ajax('/v3/admin/questionManage/del', data, 'POST');
// 编辑试题
export const editQuestion = (data: any) => ajax('/v3/admin/questionManage/update', data, 'POST');
// 添加试题
export const addQuestion = (data:any) => ajax('/v3/admin/questionManage/add', data, 'POST');
// 查看试题详情
export const getQuestionInfo = (data: any) => ajax('/v3/admin/questionManage/getQuestionDetail', data, 'GET');
// 批量导入
export const ImportQuestion = (data: any) => ajax('/v3/admin/questionManage/questionsImport', data, 'POST');

// 获取工作类别
export const getWorkType = () => ajax('/v3/admin/sys/param/getParamList', 'GET');
