import {
  action,
  makeAutoObservable,
  runInAction,
} from 'mobx';
import {
  getUserTrainList,
  getUserTrainStatus,
  deleteTrain,
} from '@/api/server/userCenterTraining';
import { message } from 'antd';

/**
 * 个人中心-个人用户-我的培训状态
 */
class UserCenterUserTrainStore {
  // 当前分页
  pageNum: number = 1;

  // 每页显示数
  pageSize: number = 6;

  // 查询关键字
  keyWord: string = '';

  // 工作类别
  workType: string = '';

  // 培训状态
  status: number|string = '';

  // 学习状态
  studyStatus: number = 1;

  // 数据总数
  total: number = 0;

  // 当前数据
  dataList: any[] = [];

  // 当前加载
  loading: boolean = true;

  // 培训状态数据
  statusTab: any[] = [
    {
      label: '全部',
      key: '',
      info: 0,
    },
    {
      label: '待培训',
      key: 1,
      info: 0,
    },
    {
      label: '培训中',
      key: 2,
      info: 0,
    },
    {
      label: '已取证',
      key: 3,
      info: 0,
    },
    {
      label: '培训结束',
      key: 4,
      info: 0,
    },
    {
      label: '退回',
      key: 0,
      info: 0,
      danger: true,
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * 获取培训列表数据
   */
  @action getUserTrainList = async (data: any = {}) => {
    const defaultData = {
      keyword: this.keyWord,
      page: this.pageNum,
      studyStatus: this.studyStatus,
      limit: this.pageSize,
      status: this.status,
      workType: this.workType,
    };
    this.loading = true;
    const res: any = await getUserTrainList({ ...defaultData, ...data });
    if (res.state.value === 0) {
      runInAction(() => {
        this.dataList = res.result.data;
        this.total = res.result.total;
        this.pageNum = res.result.page;
        this.loading = false;
        if (data.keyword !== undefined) {
          this.keyWord = data.keyword;
        }
        if (data.studyStatus !== undefined) {
          this.studyStatus = data.studyStatus;
        }
        if (data.status !== undefined) {
          this.status = data.status;
        }
        if (data.workType !== undefined) {
          this.workType = data.workType;
        }
      });
    } else {
      message.error('培训信息获取失败!');
    }
  }

  /**
   * 获取培训状态数据
   */
  @action getUserTrainStatus = async (data: any = {}) => {
    const res: any = await getUserTrainStatus(data);
    if (res.state.value === 0) {
      runInAction(() => {
        const old = JSON.parse(JSON.stringify(this.statusTab));
        /* eslint-disable no-param-reassign */
        old.forEach((item: any) => { item.info = 0; }); // 清空
        res.result?.forEach((item: any) => {
          const o = old.find((itemA: any) => itemA.key === item.trainStatus) || old[0];
          o.info = item.num;
          this.statusTab = old;
        });
      });
    } else {
      message.error('培训状态数据获取失败!');
    }
  }

  /**
   * 删除被退回的培训
   */
  @action deleteTrain = async (data: any) => {
    const res: any = await deleteTrain(data);
    if (res.state.value === 0) {
      message.success(res.message);
      return true;
    }
    message.error('培训删除失败!');
    return false;
  }
}

export default new UserCenterUserTrainStore();
