/*
 * @Description:个人用户注册
 * @Autor: CYF
 * @Date: 2022-03-16 18:14:44
 * @LastEditors: CYF
 * @LastEditTime: 2022-05-30 08:34:40
 */
import { Button, Form, Input } from 'antd';
import rules from '@/configs/formRules';

export default (({
  onEmailVcode,
}:{
  onEmailVcode: Function
}) => (
  <>
    <Form.Item
      label="个人姓名"
      name="name"
      hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
      required={false}
      rules={[{ required: true, message: '请正确输入您的真实个人姓名' }]}
    >
      <Input autoComplete="off" placeholder="请正确输入您的真实个人姓名" />
    </Form.Item>
    <Form.Item
      label="邮箱账号"
      name="email"
      hasFeedback // 配合 validateStatus 属性使用，展示校验状态图标，建议只配合 Input 组件使用
      required={false}
      rules={rules.email}
    >
      <Input autoComplete="off" placeholder="请输入您的个人邮箱" />
    </Form.Item>
    <Form.Item
      label="设置密码"
      name="password"
      required={false}
      rules={[...rules.password, { required: true, message: '请输入您的密码' }]}
    >
      <Input.Password autoComplete="new-password" placeholder="请输入密码（8位及以上包含大小写字母、数字）" />
    </Form.Item>
    <Form.Item
      label="确认密码"
      name="repeatPassword"
      required={false}
      rules={[{ required: true, message: '请再次输入您的密码' }]}
    >
      <Input.Password autoComplete="new-password" placeholder="请再次输入您的密码" />
    </Form.Item>
    <Form.Item
      label="验证码"
      name="vcode"
      required={false}
      rules={[{ required: true, message: '请输入您的验证码' }]}
    >
      <div className="email-vcode">
        <Input autoComplete="off" placeholder="请输入您的验证码" />
        <Button
          className="email-vcode-btn"
          onClick={() => { onEmailVcode(); }}
        >
          获取邮箱验证码
        </Button>
      </div>
    </Form.Item>
  </>
));
