import ajax from '../ajax';

// 获取试卷结构
export const getStuctureList = (data: any) => ajax('/v3/admin/paperStructureManage/getList', data, 'GET');
// 新增试卷结构
export const addSturcture = (data: any) => ajax('/v3/admin/paperStructureManage/add', data, 'POST');
// 编辑试卷启用状态
export const updateStatus = (data: any) => ajax('/v3/admin/paperStructureManage/updateStatus', data, 'POST');
// 删除试卷
export const removeStructure = (data: any) => ajax('/v3/admin/paperStructureManage/del', data, 'POST');
// 查看试卷详情
export const structureInfo = (data: any) => ajax('/v3/admin/paperStructureManage/getPaperStructureDetail', data, 'GET');
// 更新试卷结构

export const updateStructure = (data: any) => ajax('/v3/admin/paperStructureManage/update', data, 'POST');
