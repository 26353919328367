import { ReactNode, lazy } from 'react';

const Preview = lazy(() => import('@/adminPages/messageManagement/preview'));
const PreviewCourseList = lazy(() => import('@/pages/userCenter/myTraining/person/onlineWatch'));
const UserAgreement = lazy(() => import('@/otherPages/UserAgreement'));
const Privacy = lazy(() => import('@/otherPages/privacy'));

interface RouteConfig {
  path: string,
  key: string,
  component: ReactNode,
  redirect?: string,
  children?: RouteConfig[],
}

const routesConfig: RouteConfig[] = [
  {
    path: '/other',
    key: '预览',
    component: () => <div>预览</div>,
    redirect: '/home',
    children: [
      {
        path: '/previewWatch',
        key: '预览信息',
        component: Preview,
      },
      {
        path: '/previewCourseList',
        key: '预览培训课程组',
        component: PreviewCourseList,
      },
      {
        path: '/previewCourseGroup/:id',
        key: '预览课程组',
        component: PreviewCourseList,
      },
      {
        path: '/agreement',
        key: '用户协议',
        component: UserAgreement,
      },
      {
        path: '/privacy',
        key: '隐私政策',
        component: Privacy,
      },
    ],
  },
];

export default routesConfig;
