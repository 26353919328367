/*
 * @Descripttion:
 * @version:
 * @Author: Fanzy
 * @Date: 2022-03-08 17:00:56
 * @LastEditors: Fanzy
 * @LastEditTime: 2022-03-08 17:08:06
 */
import ajax from '../ajax';

// 获取列表数据
export const getDemoDataList = (data: any) => ajax('/mock/demoList', data, 'GET');

// 获取文章详情
export const getListInfo = (data: any) => ajax('/mock/demoListInfo', data, 'GET');
